import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import * as yup from 'yup';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import EnterpriseTypeahead from '../_DataComponents/EnterpriseTypeahead';
import { RadioGroup, Radio } from '../_DataComponents/Radio';

const EmployeeInjuredParty = (props) => {
  const {
    handleChange,
    info,
    setInfo,
    disabled,
    schema
  } = props;
  const bodyPartChange = (event) => {
    const { value } = event.target;
    const infocopy = { ...info };
    if (typeof infocopy.data.BodyPart === 'undefined') infocopy.data.BodyPart = [];
    const bodyPartIdx = infocopy.data.BodyPart.indexOf(value);
    if (bodyPartIdx < 0) {
      infocopy.data.BodyPart.push(value);
    }
    else {
      infocopy.data.BodyPart.splice(bodyPartIdx, 1);
    }
    setInfo(infocopy);
  };

  const requiredMark = () => <span className="text-danger">*</span>;
  return (
    <>
      <form>
        <Row hidden={info.data.ReportType === 'Quality'}>
          <Col>
            <Label for="FirstAidOrOsha">First Aid/OSHA</Label>
            <RadioGroup name="FirstAidOrOsha" id="FirstAidOrOsha" selectedValue={info.data.FirstAidOrOsha || ''} onChange={handleChange} disabled={disabled}>
              <Radio value="No Treatment Provided" label="No Treatment Provided" name="FirstAidOrOsha" style={{ transform: 'scale(1.5)' }} />
              <Radio value="First Aid" label="First Aid" name="FirstAidOrOsha" style={{ transform: 'scale(1.5)' }} />
              <Radio value="OSHA" label="OSHA" name="FirstAidOrOsha" style={{ transform: 'scale(1.5)' }} />
            </RadioGroup>
          </Col>
          <Col hidden={info.data.FirstAidOrOsha !== 'First Aid'}>
            <Label for="CareProvided">Care Provided {requiredMark()}</Label>
            <RadioGroup name="CareProvided" id="CareProvided" selectedValue={info.data.CareProvided || ''} onChange={handleChange} disabled={disabled}>
              <Radio value="On Site" label="On Site" name="CareProvided" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Clinic Offsite" label="Clinic Offsite" name="CareProvided" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Telemedicine" label="Telemedicine" name="CareProvided" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Emergency Medical Treatment" label="Emergency Medical Treatment" name="CareProvided" style={{ transform: 'scale(1.5)' }} />
            </RadioGroup>
          </Col>
        </Row>

        <Row hidden={info.data.ReportType === 'Quality'}>
          <Col>
            <Label for="CauseOfInjury">Cause of Injury</Label>
            <RadioGroup name="CauseOfInjury" id="CauseOfInjury" selectedValue={info.data.CauseOfInjury || ''} onChange={handleChange} disabled={disabled}>
              <Radio value="Struck By" label="Struck By" name="CauseOfInjury" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Caught Between" label="Caught Between" name="CauseOfInjury" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Slip/Trip/Fall" label="Slip/Trip/Fall" name="CauseOfInjury" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Contact with Electric Current" label="Contact with Electric Current" name="CauseOfInjury" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Contact with Stationary Object" label="Contact with Stationary Object" name="CauseOfInjury" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Fire/Explosion" label="Fire/Explosion" name="CauseOfInjury" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Exposure to Harmful Substance or Environment" label="Exposure to Harmful Substance or Environment" name="CauseOfInjury" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Animal/Insect Bite or Sting" label="Animal/Insect Bite or Sting" name="CauseOfInjury" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Other" label="Other" name="CauseOfInjury" style={{ transform: 'scale(1.5)' }} />
            </RadioGroup>
            <Label for="CauseOfInjuryOther" hidden={info.data.CauseOfInjury !== 'Other'}>Cause of Injury (Other)</Label>
            <Input type="text" name="CauseOfInjuryOther" hidden={info.data.CauseOfInjury !== 'Other'} value={info.data.CauseOfInjuryOther || ''} onChange={(e) => { handleChange(e); }} disabled={disabled} />
          </Col>
          <Col>
            <Label for="InjuryType">Injury Type</Label>
            <RadioGroup name="InjuryType" id="InjuryType" selectedValue={info.data.InjuryType || ''} onChange={handleChange} disabled={disabled}>
              <Radio value="Abrasion" label="Abrasion" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Allergic Reaction" label="Allergic Reaction" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Amputation" label="Amputation" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Avulsion" label="Avulsion" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Burn" label="Burn" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Concussion" label="Concussion" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Cold Stress" label="Cold Stress" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Contusion" label="Contusion" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Dislocation" label="Dislocation" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Fracture" label="Fracture" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Foreign Body" label="Foreign Body" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Frost Bite" label="Frost Bite" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Heat Stress" label="Heat Stress" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Laceration" label="Laceration" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Muscle Soreness" label="Muscle Soreness" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Puncture" label="Puncture" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Skin Rash" label="Skin Rash" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Sprain" label="Sprain" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Strain" label="Strain" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Other" label="Other" name="InjuryType" style={{ transform: 'scale(1.5)' }} />
            </RadioGroup>
            <Label for="InjuryTypeOther" hidden={info.data.InjuryType !== 'Other'}>Injury Type (Other)</Label>
            <Input type="text" name="InjuryTypeOther" hidden={info.data.InjuryType !== 'Other'} value={info.data.InjuryTypeOther || ''} onChange={handleChange} />
          </Col>
        </Row>

        <Row hidden={info.data.ReportType === 'Quality'}>
          <Col>
            <Label for="BodyPart">Body Part</Label>
            <FormGroup check>
              <Label check><Input name="BodyPart" type="checkbox" value="Abdomen" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Abdomen') > -1 : false} disabled={disabled} />Abdomen</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Ankle" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Ankle') > -1 : false} disabled={disabled} />Ankle</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Arm" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Arm') > -1 : false} disabled={disabled} />Arm</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Back" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Back') > -1 : false} disabled={disabled} />Back</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Chest" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Chest') > -1 : false} disabled={disabled} />Chest</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Ear" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Ear') > -1 : false} disabled={disabled} />Ear</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Elbow" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Elbow') > -1 : false} disabled={disabled} />Elbow</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Eye" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Eye') > -1 : false} disabled={disabled} />Eye</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Face" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Face') > -1 : false} disabled={disabled} />Face</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Finger(s)" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Finger(s)') > -1 : false} disabled={disabled} />Finger(s)</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Foot" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Foot') > -1 : false} disabled={disabled} />Foot</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Groin" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Groin') > -1 : false} disabled={disabled} />Groin</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Hand" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Hand') > -1 : false} disabled={disabled} />Hand</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Head" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Head') > -1 : false} disabled={disabled} />Head</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Hip" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Hip') > -1 : false} disabled={disabled} />Hip</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Knee" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Knee') > -1 : false} disabled={disabled} />Knee</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Leg" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Leg') > -1 : false} disabled={disabled} />Leg</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Mouth" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Mouth') > -1 : false} disabled={disabled} />Mouth</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Neck" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Neck') > -1 : false} disabled={disabled} />Neck</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Rib(s)" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Rib(s)') > -1 : false} disabled={disabled} />Rib(s)</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Shoulder" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Shoulder') > -1 : false} disabled={disabled} />Shoulder</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Teeth" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Teeth') > -1 : false} disabled={disabled} />Teeth</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Toes" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Toes') > -1 : false} disabled={disabled} />Toes</Label>
              <br /><Label check><Input name="BodyPart" type="checkbox" value="Wrist" onChange={bodyPartChange} checked={Array.isArray(info.data.BodyPart) ? info.data.BodyPart.indexOf('Wrist') > -1 : false} disabled={disabled} />Wrist</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup check>
              <Label check style={{ margin: '1rem 0' }}>
                <Input name="isAeEmployee" type="checkbox" onChange={() => handleChange(!info.data.IsAeEmployee, 'IsAeEmployee')} checked={info.data.IsAeEmployee || false} disabled={disabled} />Was this an Aldridge Employee?
              </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col hidden={!info.data.IsAeEmployee}>
            <EnterpriseTypeahead
              onChange={handleChange}
              name="PrimaryEmployeeAffected"
              valueKey="prtmstid"
              optionDisplay={['option_PreferredFirstName', ' ', 'option_MiddleName', ' ', 'option_PreferredLastName', ' ', 'option_Suffix']}
              label="Primary Employee(s)"
              value={(typeof info.data.PrimaryEmployeeAffected !== 'undefined' && info.data.PrimaryEmployeeAffected.length !== 0) ? info.data.PrimaryEmployeeAffected : -1}
              collection="Employees"
              where={[['Status', '==', 'A']]}
              orderBy="PreferredLastName"
              disabled={disabled}
              required
              multiple
              style={{ height: '100px' }}
              invalid={!yup.reach(schema, 'data.PrimaryEmployeeAffected').isValidSync(info.data.PrimaryEmployeeAffected)}
            />
          </Col>
          <Col hidden={info.data.IsAeEmployee}>
            <Label for="PrimaryEmployeeAffectedOther">Primary Employee (Non AE Employee) {requiredMark()}</Label>
            <Input name="PrimaryEmployeeAffectedOther" type="text" value={info.data.PrimaryEmployeeAffectedOther || ''} onChange={handleChange} disabled={disabled} invalid={!yup.reach(schema, 'data.PrimaryEmployeeAffectedOther').isValidSync(info.data.PrimaryEmployeeAffectedOther)} />
          </Col>
        </Row>

        <Row>
          <Col>
            <EnterpriseTypeahead
              onChange={handleChange}
              name="EmployeeWitnesses"
              valueKey="prtmstid"
              multiple
              optionDisplay={['option_PreferredFirstName', ' ', 'option_MiddleName', ' ', 'option_PreferredLastName', ' ', 'option_Suffix']}
              label="List any Employees that WITNESSED the Incident"
              value={(typeof info.data.EmployeeWitnesses !== 'undefined' && info.data.EmployeeWitnesses.length !== 0) ? info.data.EmployeeWitnesses : -1}
              collection="Employees"
              where={[['Status', '==', 'A']]}
              orderBy="PreferredLastName"
              disabled={disabled}
              style={{ height: '100px' }}
            />
            <FormGroup check>
              <Label check>
                <Input name="nonAeWitnesses" type="checkbox" checked={info.data.nonAeWitnesses || false} onChange={() => handleChange(!info.data.nonAeWitnesses, 'nonAeWitnesses')} disabled={disabled} />
                Check Here if any non Aldridge Employees Witnessed the event
              </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col hidden={!info.data.nonAeWitnesses}>
            <Label for="EmployeeWitnessesOther">Primary Employee (Non AE Employee)</Label>
            <Input name="EmployeeWitnessesOther" type="textarea" value={info.data.EmployeeWitnessesOther} onChange={handleChange} disabled={disabled} />
          </Col>
        </Row>
      </form>
    </>
  );
};

EmployeeInjuredParty.propTypes = {
  handleChange: PropTypes.func.isRequired,
  info: PropTypes.objectOf(PropTypes.any).isRequired,
  setInfo: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  schema: PropTypes.objectOf(PropTypes.any).isRequired
};

export default EmployeeInjuredParty;
