/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { firestore } from '../firebase';
import { Table, Button } from 'reactstrap';


const ShopsList = (props) => {
  const { history } = props;
  const [Shops, setShops] = useState([]);

  useEffect(() => {
    firestore.collection('Shops').onSnapshot((snap) => {
      const all = [];
      snap.forEach(d => {
        all.push(d.data());
      })
      setShops(all);
    })
  }, [])
  
  return (
    <div>
      <div style={{width: '100%', textAlign: 'right'}}>
        <Link to="shops/new">
          <Button color="primary">New Shop</Button>
        </Link>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Shop Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {Shops.map(s => {
            return (<tr>
              <td onClick={() => {history.push(`/Shops/${s.id}`);}}>{s.data.ShopName}</td>
              <td onClick={() => {history.push(`/Shops/${s.id}`);}}>{s.data.Status}</td>
            </tr>)
          })}
        </tbody>
      </Table>
    </div>
  )
}


ShopsList.propTypes = {};
ShopsList.defaultProps = {};


export default ShopsList;