/* eslint-disable */
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';

// import { createBrowserHistory } from 'history';
import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import ReportList from './ReportList';
import Report from './Report';
import ShopsList from './ShopsList';
import Shops from './Shops';

const Application = () => {
  const user = useContext(UserContext);
  const page = () => {
    if (!user) {
      return <SignIn />;
    }
    if (user && (user.admin || user.user)) {
      return (
        <Switch>
          <Route exact path='/' component={ReportList} />
          <Route exact path='/reports' component={ReportList} />
          <Route exact path='/reports/:id' component={Report} />
          <Route exact path='/shops' component={ShopsList} />
          <Route exact path='/shops/:id' component={Shops} />
          <Route exact>
            <NoMatch />
          </Route>
        </Switch>
      );
    }

    return <Unauthorized />;
  };

  return (
    <>
      <div className='appBody'>{page()}</div>
    </>
  );
};
export default Application;
