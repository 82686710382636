/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, {
  useState,
  useEffect,
  useContext
} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  Input, Label, FormGroup
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import * as yup from 'yup';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import ReactPDF, { PDFViewer } from '@react-pdf/renderer';
import { UserContext } from '../providers/UserProvider';

import { firestore, storage } from '../firebase';
import Breadcrumbs from '../_GlobalComponents/breadcrumb';

import { useFirebaseMutation } from '../_helpers/fetch';
import GeneralInfo from './GeneralInfo';
import JobBackgroundInfo from './JobBackgroundInfo';
import EmployeeInjuredParty from './EmployeeInjuredParty';
import EmailPreview from './EmailPreview';
import { emailNotification } from '../_DataComponents/FirstReportEmail';

const Report = (props) => {
  const user = useContext(UserContext);
  const [pageState, setPageState] = useState({
    activeSearch: true,
    collapse: false,
    accordion: [true, false, false, false],
    custom: [true, false, false, false],
    status: '',
    fadeIn: true,
    timeout: 300
  });

  const [skipAttachments, setSkipAttachments] = useState(false);
  const { match, history } = props;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [report, setReport] = useState({
    id: null,
    SharedWith: [],
    CreatedDate: new Date().toJSON(),
    CreatedBy: user.email,
    ModifiedDate: '',
    ModifiedBy: '',
    data: {
      Auto: '',
      BeginToolUsed: '',
      BodyPart: [],
      CategoryType: '',
      CauseOfInjury: '',
      CauseOfInjuryOther: '',
      City: '',
      Company: '',
      CompanyOther: '',
      CorrectiveActions: '',
      Cost: '',
      Damage: '',
      DescribeIncident: '',
      Division: [],
      Draft: true,
      EmployeeWitnesses: [],
      EmployeeWitnessesOther: '',
      EquipmentNumber: '',
      EquipmentOther: '',
      EstimatedIncidentCost: '',
      Explanation911: '',
      FirstAidOrOsha: '',
      ForemanReportingIncident: [],
      Hours: '',
      InjuryType: '',
      InjuryTypeOther: '',
      IncidentDate: '',
      IncidentLocation: '',
      IsAeEmployee: true,
      Job: [],
      MVIType: '',
      NonAeWitnesses: '',
      OccurrenceType: '',
      PrimaryCause: '',
      PrimaryEmployeeAffected: [],
      PrimaryEmployeeAffectedOther: '',
      ProjectManager: [],
      ReferenceNumber: '',
      ReportDate: '',
      ReportType: 'Safety',
      State: '',
      SubcontractorName: '',
      Submitter: '',
      Superintendent: [],
      SupervisorInformed: '',
      SupervisorInformedExplain: '',
      UtilityDamage: '',
      VicePresident: [],
      Was911Contacted: '',
      WasAmbulanceDispatched: ''
    }
  });
  const {
    firebaseCall: sendEmail, data: emailData, error: emailError, loading: emailLoading
  } = useFirebaseMutation('SendEmail', {});
  const schema = yup.object().shape({
    id: yup.string().required(),
    SharedWith: yup.array().of(yup.string()),
    CreatedDate: yup.string(),
    CreatedBy: yup.string(),
    ModifiedDate: yup.string(),
    ModifiedBy: yup.string(),
    data: yup.object().shape({
      Draft: yup.bool(),
      Submitter: yup.string().required(),

      Company: yup.string().required('Company is required in General Information.'),

      Division: yup.array().of(yup.string()).min(1, 'You must select a Division in General Information.'),

      OccurrenceType: yup.string().required('Occurrence Type is required in General Information.'),
      CategoryType: yup.string().required('Category Type is required in General Information.'),
      BeginToolUsed: yup.string().when('OccurenceType', {
        is: (value) => ['Near Miss', 'Good Catch'].indexOf(value) > -1,
        then: yup.string().required('"Was the BEGIN Tool Used?" is required in General Information.'),
        else: yup.string()
      }),

      EstimatedIncidentCost: yup.string().when('CategoryType', {
        is: (value) => ['Property Damage', 'Theft/Vandalism', 'Motor Vehicle', 'Motor Vehicle-Theft/Vandalism', 'Equipment Damage', 'Environmental', 'Utility Hit'].indexOf(value) > -1,
        then: yup.string().required(`Estimated Incident Cost is required in General Information when filling out a(n) ${report.data.CategoryType} Incident.`),
        else: yup.string()
      }),
      EquipmentNumber: yup.string().when('CategoryType', {
        is: (value) => ['Motor Vehicle', 'Motor Vehicle-Theft/Vandalism', 'Equipment Damage'].indexOf(value) > -1,
        then: yup.string().required(`Equipment Number is required in General Information when filling out a(n) ${report.data.CategoryType} Incident.`),
        else: yup.string()
      }),
      Auto: yup.string(),
      Damage: yup.string().when('CategoryType', {
        is: (value) => ['Motor Vehicle', 'Motor Vehicle-Theft/Vandalism', 'Equipment Damage'].indexOf(value) > -1,
        then: yup.string().required(`Damage is required in General Information when filling out a(n) ${report.data.CategoryType} Incident.`),
        else: yup.string()
      }),
      EquipmentOther: yup.string().when('Damage', {
        is: 'Other',
        then: yup.string().required('Equipment Type Damaged (Other) is required in General Information.'),
        else: yup.string()
      }),
      UtilityDamage: yup.string().when('CategoryType', {
        is: (value) => ['Utility Hit'].indexOf(value) > -1,
        then: yup.string().required(`Utility Damage is required in General Information when filling out a(n) ${report.data.CategoryType} Incident.`),
        else: yup.string()
      }),

      DescribeIncident: yup.string().required('Description of Incident is required in General Information.'),
      IncidentDate: yup.string().required('Incident Date is required in General Information.'),
      ReportDate: yup.string(),
      ProjectManager: yup.array().of(yup.string()).min(1, 'You must select a Project Manager in General Information.'),
      Superintendent: yup.array().of(yup.string()),
      VicePresident: yup.array().of(yup.string()).min(1, 'You must select a Vice President in General Information.'),

      SupervisorInformed: yup.string(),
      SupervisorInformedExplain: yup.string(),
      Was911Contacted: yup.string(),
      WasAmbulanceDispatched: yup.string(),
      Explanation911: yup.string(),

      IncidentLocation: yup.string(),
      Job: yup.array().of(yup.string()).when('IncidentLocation', {
        is: 'Job Site',
        then: yup.array().of(yup.string().min(1)),
        else: yup.array().of(yup.string())
      }),
      City: yup.string(),
      State: yup.string().required('State is required in Job & Background Information.'),
      Customer: yup.string().required('Customer is required in Job & Background Information.'),
      ForemanReportingIncident: yup.array().of(yup.string()),

      FirstAidOrOsha: yup.string(),
      CareProvided: yup.string().when('FirstAidOrOsha', {
        is: 'First Aid',
        then: yup.string().required('Care Provided is when First Aid has been selected.'),
        else: yup.string()
      }),
      CauseOfInjury: yup.string(),
      CauseOfInjuryOther: yup.string(),
      InjuryType: yup.string(),
      InjuryTypeOther: yup.string(),
      BodyPart: yup.array().of(yup.string()),

      IsAeEmployee: yup.bool(),
      PrimaryEmployeeAffected: yup.array().of(yup.string()).when('IsAeEmployee', {
        is: true,
        then: yup.array().of(yup.string()).min(1, 'You must select an Employee Witness in Employee or Injured Party.'),
        else: yup.array().of(yup.string())
      }),
      PrimaryEmployeeAffectedOther: yup.string().when('IsAeEmployee', {
        is: false,
        then: yup.string().required('You must enter an Employee Witness in Employee or Injured Party.'),
        else: yup.string()
      }),
      NonAeWitnesses: yup.string(),
      EmployeeWitnesses: yup.array().of(yup.string()),
      EmployeeWitnessesOther: yup.string(),
      ReferenceNumber: yup.string(),
      SubcontractorName: yup.string(),
    })
  });

  useEffect(async () => {
    const RECORDID = await (async () => {
      if (match.params.id === 'new') {
        return (await firestore.collection('Reports').doc()).id;
      }
      return match.params.id;
    })();

    try {
      setLoading(true);
      firestore.collection('Reports').doc(RECORDID).onSnapshot((doc) => {
        if (doc.exists) {
          const record = doc.data();
          setReport(record);
        }
        else {
          const record = { ...report, id: RECORDID };
          record.data.Submitter = user.name;
          if (record.SharedWith.indexOf(user.email) === -1) record.SharedWith.push(user.email);
          setReport(record);
        }
        setLoading(false);
      }, (err) => {
        toast.error(err.message);
      });
    }
    catch (err) {
      toast.error(err.message);
    }
  }, []);

  useEffect(() => {
    if (typeof emailData !== 'undefined') {
      toast.success('Email sent successfully.');
    }
    if (typeof emailError !== 'undefined') {
      toast.error(`Email failed to send. ${emailError}`);
    }

    if (typeof emailError === 'undefined' && !emailLoading && saving) {
      const finalSet = { ...report };
      finalSet.data.Draft = false;
      firestore.collection('Reports').doc(finalSet.id).set(finalSet, { merge: true }).then(() => {
        setSaving(false);
        setReport(finalSet);
        toast.success('Record Submitted Successfully.', { autoClose: 2000 });
      });
    }
    if (typeof emailError !== 'undefined' && !emailLoading && saving) {
      setSaving(false);
    }
  }, [emailData, emailError]);

  useEffect(() => {
    const uReport = { ...report };
    if (uReport.data.IncidentLocation === 'Job Site') {
      uReport.data.Location = uReport.data.JobDisplay || 'Job Site';
    }
    else if (uReport.data.IncidentLocation === 'Shop') {
      uReport.data.Location = uReport.data.ShopDisplay || 'Shop';
    }
    else {
      uReport.data.Location = uReport.data.IncidentLocation;
    }
    setReport(uReport);
  }, [report.data.IncidentLocation, report.data.Shop, report.data.Job]);

  const saveCurrent = async () => {
    const finalSet = { ...report };
    setSaving(true);
    await firestore.collection('Reports').doc(finalSet.id).set(finalSet, { merge: true });
    setSaving(false);
    toast.success('Record Submitted Successfully.', { autoClose: 2000 });
    if (match.params.id === 'new') {
      history.push(`/reports/${report.id}`);
    }
  };
  const submit = () => {
    const finalSet = { ...report };
    if (finalSet.data.OccurrenceType === 'Quality Events - Rework') {
      toast.info('Emails are not sent for this occurence type.');
      finalSet.data.Draft = false;
      firestore.collection('Reports').doc(finalSet.id).set(finalSet, { merge: true }).then(() => {
        setSaving(false);
        setReport(finalSet);
        toast.success('Record Submitted Successfully.', { autoClose: 2000 });
      });
      return;
    }
    if (
      (finalSet.data.Company === 'Subcontractor' && finalSet.data.CompanyOther.length > 0)
      || (finalSet.data.Company === '3rd Party' && finalSet.data.CompanyOther !== '')
      || (finalSet.data.Company === 'Joint Venture' && finalSet.data.CompanyOther !== '')
      || (finalSet.data.Company === 'Aldridge Electric' && finalSet.data.CompanyOther === '')
    ) {
      schema.validate(finalSet, { abortEarly: false }).then(async () => {
        setSaving(true);

        const ref = storage.ref(`${finalSet.id}/photo`);
        const urls = [];
        const listItems = (await ref.listAll()).items;
        if (!skipAttachments) {
          for (let i = 0; i < listItems.length; i++) {
            // eslint-disable-next-line no-await-in-loop
            urls.push({ filename: listItems[i].name, path: await listItems[i].getDownloadURL() });
          }
        }
        setReport(finalSet);
        await emailNotification(finalSet, sendEmail, urls, skipAttachments);
      }).catch((err) => {
        console.log(err);

        if (err.errors.length > 0) {
          toast.error(<div>{err.errors.map((e) => <div>{e}</div>)}</div>, { autoClose: 5000 });
        }
      });
    }
    else {
      toast.error('You must fill out the Subcontractor/3rd Party.');
    }
  };

  const handleChange = (event, name, display) => {
    try {
      let id;
      let value;
      const changedReport = { ...report };
      if (typeof name === 'object' && name !== null) {
        id = name.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      }
      else {
        id = name || event.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      }
      if (typeof display !== 'undefined') {
        changedReport.data[`${name}Display`] = display;
      }
      // console.log(id, value);
      if (id === 'IncidentDate') {
        if (event.target.value === '') {
          changedReport.data[id] = event.target.value;
          changedReport.data[`${id}_Date`] = event.target.value;
          changedReport.data[`${id}_Time`] = event.target.value;
        }
        else {
          changedReport.data[id] = event.target.value;
          [changedReport.data[`${id}_Date`], changedReport.data[`${id}_Time`]] = event.target.value.split('T');
        }
      }
      changedReport.ModifiedBy = user.email;
      changedReport.ModifiedDate = new Date().toJSON();

      changedReport.data[id] = value;
      setReport(changedReport);
    }
    catch (err) {
      console.log(err);
      toast.error(err.message, { autoClose: 8000 });
    }
  };

  const toggleAccordion = (tab) => {
    const prevState = pageState.accordion;
    const state = prevState.map((x, index) => (tab === index ? !x : false));

    const updateS = { ...pageState, accordion: state };
    setPageState(updateS);
  };

  const disabledForm = (report.data.Draft.toString() === 'false') && !user.admin;

  useEffect(() => {
    if (report.data.Company === 'Aldridge Electric') {
      report.data.CompanyOther = '';
      setReport({ ...report });
    }
    if (report.data.Company === 'Subcontractor' && !(Array.isArray(report.data.CompanyOther))) {
      report.data.CompanyOther = [];
      setReport({ ...report });
    }
    if (report.data.Company === '3rd Party' && typeof report.data.CompanyOther !== 'string') {
      report.data.CompanyOther = '';
      setReport({ ...report });
    }
    if (report.data.Company === 'Joint Venture' && typeof report.data.CompanyOther !== 'string') {
      report.data.CompanyOther = '';
      setReport({ ...report });
    }
  }, [report.data.Company]);

  useEffect(() => {
    if (report.data.ProjectManager.length > 0) {
      firestore.collection('Employees').where('prtmstid', '==', report.data.ProjectManager[0]).get().then((pm) => {
        if (!pm.empty) {
          const doc = pm.docs[0].data();
          const newReport = { ...report };
          newReport.data.ProjectManagerEmail = doc.Email || '';
          setReport(newReport);
        }
        else {
          const newReport = { ...report };
          newReport.data.ProjectManagerEmail = '';
          setReport(newReport);
        }
      });
    }
  }, [report.data.ProjectManager]);

  useEffect(() => {
    if (['Motor Vehicle', 'Motor Vehicle-Theft/Vandalism', 'Equipment Damage'].indexOf(report.data.CategoryType) === -1) {
      // shut down equipment stuff
      const changedReport = { ...report };
      changedReport.data.Damage = '';
      changedReport.data.EquipmentOther = '';
      setReport(changedReport);
    }
  }, [report.data.CategoryType]);

  console.log(report);
  return (
    <>
      <Breadcrumbs path={[
        { name: 'Reports', active: true, link: '/reports' },
        { name: `Report - ${report.id}`, active: false }
      ]}
      />
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        position: 'sticky',
        backgroundColor: 'white',
        top: '0',
        padding: '.5rem',
        zIndex: 100
      }}
      >
        <Button className="bg-primary" onClick={saveCurrent}>Save</Button>
        &nbsp;
        <Button className="bg-success" onClick={submit} hidden={!report.data.Draft}>Finalize Report</Button>
        &nbsp;
        <Button className="bg-danger"><Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Cancel</Link></Button>
      </div>
      <div>
        {
          saving ? (
            <div style={{
              display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
            }}
            />
          ) : <></>
        }

        <Container fluid>
          {
            loading ? (
              <div style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
              }}
              >
                <Loader
                  type="TailSpin"
                  color="#3b78e7"
                  height={150}
                  width={150}
                />
                Loading Report Data...
              </div>
            )
              : (
                <div id="accordion" className="accordion-wrapper mb-3">
                  <FormGroup check hidden>
                    <Label check>
                      <Input type="checkbox" value={skipAttachments} onChange={() => setSkipAttachments(!skipAttachments)} />
                      If your email is failing to send because of
                      exceeding file size, send email without attachments.
                    </Label>
                  </FormGroup>
                  <Card>
                    <CardHeader id="headingOne">
                      <Button block color="link" className="text-left m-0 p-0" onClick={() => toggleAccordion(0)} aria-expanded={pageState.accordion[0]} aria-controls="collapseOne">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <h5 className="m-0 p-0">General Information</h5>
                          {
                            pageState.accordion[0]
                              ? <FontAwesomeIcon icon={faMinus} />
                              : <FontAwesomeIcon icon={faPlus} />
                          }
                        </div>
                      </Button>
                    </CardHeader>
                    <Collapse isOpen={pageState.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                      <CardBody>
                        <GeneralInfo
                          match={match}
                          info={report}
                          handleChange={handleChange}
                          disabled={disabledForm}
                          schema={schema}
                        />
                        <Button color="warning" className="mt-1" onClick={() => toggleAccordion(1)}>Next</Button>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader className="b-radius-0" id="headingTwo">
                      <Button block color="link" className="text-left m-0 p-0" onClick={() => toggleAccordion(1)} aria-expanded={pageState.accordion[1]} aria-controls="collapseTwo">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <h5 className="m-0 p-0">Job and Background Information</h5>
                          {
                            pageState.accordion[1]
                              ? <FontAwesomeIcon icon={faMinus} />
                              : <FontAwesomeIcon icon={faPlus} />
                          }
                        </div>
                      </Button>
                    </CardHeader>
                    <Collapse isOpen={pageState.accordion[1]} data-parent="#accordion" id="collapseTwo">
                      <CardBody>
                        <JobBackgroundInfo
                          match={match}
                          info={report}
                          setInfo={setReport}
                          handleChange={handleChange}
                          disabled={disabledForm}
                          schema={schema}
                        />
                        <Button color="warning" className="mt-1" onClick={() => toggleAccordion(2)}>Next</Button>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader className="b-radius-0" id="headingThree">
                      <Button block color="link" className="text-left m-0 p-0" onClick={() => toggleAccordion(2)} aria-expanded={pageState.accordion[2]} aria-controls="collapseThree">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <h5 className="m-0 p-0">Employee or Injured Party</h5>
                          {
                            pageState.accordion[2]
                              ? <FontAwesomeIcon icon={faMinus} />
                              : <FontAwesomeIcon icon={faPlus} />
                          }
                        </div>
                      </Button>
                    </CardHeader>
                    <Collapse isOpen={pageState.accordion[2]} data-parent="#accordion" id="collapseThree">
                      <CardBody>
                        <EmployeeInjuredParty
                          match={match}
                          info={report}
                          setInfo={setReport}
                          handleChange={handleChange}
                          disabled={disabledForm}
                          schema={schema}
                        />
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader className="b-radius-0" id="headingFour">
                      <Button block color="link" className="text-left m-0 p-0" onClick={() => toggleAccordion(3)} aria-expanded={pageState.accordion[3]} aria-controls="collapseFour">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <h5 className="m-0 p-0">Email Preview</h5>
                          {
                            pageState.accordion[3]
                              ? <FontAwesomeIcon icon={faMinus} />
                              : <FontAwesomeIcon icon={faPlus} />
                          }
                        </div>
                      </Button>
                    </CardHeader>
                    {report.data.OccurrenceType !== 'Quality Events - Rework' ? (
                      <Collapse isOpen={pageState.accordion[3]} data-parent="#accordion" id="collapseThree">
                        <CardBody>
                          <EmailPreview
                            match={match}
                            info={report}
                            setInfo={setReport}
                            handleChange={handleChange}
                            disabled={disabledForm}
                            schema={schema}
                          />
                        </CardBody>
                      </Collapse>
                    ) : null}
                  </Card>
                </div>
              )
          }
        </Container>
      </div>
    </>
  );
};

Report.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.shape({
      state: PropTypes.objectOf(PropTypes.any)
    }),
    push: PropTypes.func,
    replace: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    block: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string
  }),
};
Report.defaultProps = {
  history: {
    push: () => { }
  },
  match: {
    url: ''
  }
};

export default Report;
