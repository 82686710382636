import React, { useContext } from 'react';
import {
  Button
} from 'reactstrap';
import { UserContext } from '../providers/UserProvider';
import { auth } from '../firebase';

const Unauthorized = () => {
  const user = useContext(UserContext);
  return (
    <>
      <div>
        You are currently unauthorized for this application with this account
        ({user.email}). Please contact the helpdesk if you should have access.
      </div>
      <div>
        <Button type="button" onClick={() => { auth.signOut(); }}>Sign out</Button>
      </div>
    </>
  );
};
export default Unauthorized;
