import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Row,
  Label,
  Input
} from 'reactstrap';
import * as yup from 'yup';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import EnterpriseTypeahead from '../_DataComponents/EnterpriseTypeahead';
import { RadioGroup, Radio } from '../_DataComponents/Radio';
import { firestore } from '../firebase';

const JobBackgroundInfo = (props) => {
  const {
    handleChange,
    info,
    setInfo,
    disabled,
    schema
  } = props;

  const jobChange = (event, name, display) => {
    if (event.length > 0) {
      firestore.collection('Jobs').doc(event[0].toString()).get().then((res) => {
        if (res.exists) {
          const resData = res.data();
          const updatedReport = { ...info };
          updatedReport.data.City = resData.City;
          updatedReport.data.State = resData.State;
          updatedReport.data.Job = event;
          updatedReport.data.JobDisplay = display;
          setInfo(updatedReport);
        }
      });
    }
    else {
      const updatedReport = { ...info };
      updatedReport.data.City = '';
      updatedReport.data.State = '';
      updatedReport.data.Job = '';
      updatedReport.data.JobDisplay = '';
      setInfo(updatedReport);
    }
  };
  const requiredMark = () => <span className="text-danger">*</span>;

  return (
    <>
      <form>
        <Row>
          <Col>
            <Label for="IncidentLocation">Incident Location</Label>
            <RadioGroup name="IncidentLocation" id="IncidentLocation" selectedValue={info.data.IncidentLocation || ''} onChange={handleChange} disabled={disabled}>
              <Radio value="Job Site" label="Job Site" name="IncidentLocation" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Shop" label="Shop" name="IncidentLocation" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Office" label="Office" name="IncidentLocation" style={{ transform: 'scale(1.5)' }} />
              <Radio value="Yard" label="Yard" name="IncidentLocation" style={{ transform: 'scale(1.5)' }} />
            </RadioGroup>
          </Col>
        </Row>
        <Row hidden={info.data.IncidentLocation !== 'Job Site'}>
          <Col>
            <EnterpriseTypeahead
              onChange={jobChange}
              name="Job"
              valueKey="jctdscid"
              optionDisplay={['option_JobNumber', '.', 'option_SubJobNumber', ' ', 'option_Division', ' - ', 'option_JobDescription']}
              label="Job Number"
              value={(typeof info.data.Job !== 'undefined' && info.data.Job.length !== 0) ? info.data.Job : -1}
              collection="Jobs"
              where={[['JobStatus', '==', 'Open']]}
              orderBy="JobNumber"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row hidden={info.data.IncidentLocation !== 'Shop'}>
          <Col>
            <EnterpriseTypeahead
              onChange={handleChange}
              name="Shop"
              valueKey="id"
              optionDisplay={['option_ShopName']}
              label="Shop"
              value={(typeof info.data.Shop !== 'undefined' && info.data.Shop.length !== 0) ? info.data.Shop : -1}
              collection="Shops"
              where={[['data.Status', '==', 'Active']]}
              orderBy="data.ShopName"
              disabled={disabled}
              enterprise={false}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="City">City</Label>
            <Input name="City" type="text" onChange={handleChange} value={info.data.City || ''} disabled={disabled} />
          </Col>
          <Col>
            <Label for="State">State {requiredMark()}</Label>
            <Input name="State" type="text" onChange={handleChange} value={info.data.State || ''} disabled={disabled} invalid={!yup.reach(schema, 'data.State').isValidSync(info.data.State)} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EnterpriseTypeahead
              onChange={handleChange}
              name="Foreman"
              valueKey="prtmstid"
              optionDisplay={['option_PreferredFirstName', ' ', 'option_MiddleName', ' ', 'option_PreferredLastName', ' ', 'option_Suffix']}
              label="Foreman"
              value={(typeof info.data.Foreman !== 'undefined' && info.data.Foreman.length !== 0) ? info.data.Foreman : -1}
              collection="Employees"
              where={[['Status', '==', 'A']]}
              orderBy="PreferredLastName"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="Customer">Customer {requiredMark()}</Label>
            <Input name="Customer" type="text" onChange={handleChange} value={info.data.Customer || ''} disabled={disabled} invalid={!yup.reach(schema, 'data.Customer').isValidSync(info.data.Customer)} />
          </Col>
        </Row>
      </form>
    </>
  );
};

JobBackgroundInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  info: PropTypes.objectOf(PropTypes.any).isRequired,
  setInfo: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  schema: PropTypes.objectOf(PropTypes.any).isRequired
};

export default JobBackgroundInfo;
