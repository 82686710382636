/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input, FormGroup } from 'reactstrap';

const RadioGroup = (props) => {
  const {
    name, onChange, children, invalid, selectedValue, disabled
  } = props;
  return (
    <>
      <FormGroup check>
        <div id={name}>
          {
            children.length > 0
              ? children.map((c, idx) => (
                React.cloneElement(c, {
                  onChange, name, invalid, key: `${name}_${idx}`, selectedValue, disabled
                })
              ))
              : <></>
          }
        </div>
      </FormGroup>
    </>
  );
};

const Radio = (props) => {
  const {
    label, name, value, invalid, style, onChange, hidden, selectedValue, disabled
  } = props;
  return (
    <FormGroup hidden={hidden} check>
      <Label style={{ fontWeight: '400' }} key={value} check>
        <Input type="radio" name={`${name}`} invalid={invalid} value={value} style={style} onChange={onChange} checked={selectedValue === value} disabled={disabled} />{' '}
        <span style={{ paddingLeft: '10px' }}>{label}</span>
      </Label>
      <br />
    </FormGroup>
  );
};

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  invalid: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  hidden: PropTypes.bool,
  selectedValue: PropTypes.string,
  disabled: PropTypes.bool,
};
Radio.defaultProps = {
  label: '',
  name: '',
  value: '',
  invalid: false,
  style: {},
  hidden: false,
  onChange: () => { },
  selectedValue: '',
  disabled: false
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
RadioGroup.defaultProps = {
  invalid: false,
  disabled: false
};

export {
  Radio, RadioGroup
};
