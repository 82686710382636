/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Button, FormGroup, Label, Input, Card, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumbs from '../_GlobalComponents/breadcrumb';
import { UserContext } from '../providers/UserProvider';
import { RadioGroup, Radio } from '../_DataComponents/Radio';
import { firestore } from '../firebase';

const Shops = (props) => {
  const { match, history } = props;
  const user = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Shop, setShop] = useState({
    id: '',
    CreatedDate: new Date().toJSON(),
    CreatedBy: user.email,
    ModifiedDate: '',
    ModifiedBy: '',
    data: {
      ShopName: '',
      Status: 'Active'
    }
  });

  useEffect(() => {
    if(match.params.id !== 'new') {
      firestore.collection('Shops').doc(match.params.id).onSnapshot((snap) => {
        if(snap.exists) {
          setShop(snap.data());
        }
      })
    }
  }, [])
  const handleChange = (event, name, display) => {
    try {
      let id;
      let value;
      const changedReport = { ...Shop };
      if (typeof name === 'object' && name !== null) {
        id = name.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      }
      else {
        id = name || event.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      }
      if (typeof display !== 'undefined') {
        changedReport.data[`${name}Display`] = display;
      }
      if (event.target.value === '') {
        changedReport.data[id] = event.target.value;
        changedReport.data[`${id}_Date`] = event.target.value;
        changedReport.data[`${id}_Time`] = event.target.value;
      }
      changedReport.ModifiedBy = user.email;
      changedReport.ModifiedDate = new Date().toJSON();

      changedReport.data[id] = value;
      setShop(changedReport);
    }
    catch (err) {
      console.log(err);
      toast.error(err.message, { autoClose: 8000 });
    }
  };
  
  const saveCurrent = async () => {
    const finalSet = { ...Shop };
    setSaving(true);
    if(match.params.id === 'new') {
      const docRef = firestore.collection('Shops').doc();
      finalSet.id = docRef.id;
      docRef.set(finalSet, { merge: true });
    } else {
      const docRef = firestore.collection('Shops').doc(match.params.id);
      finalSet.id = docRef.id;
      docRef.set(finalSet, { merge: true });
    }
    setSaving(false);
    history.push('/Shops')
    toast.success('Record Submitted Successfully.', { autoClose: 2000 });
  };

  return (
    <>
      <Breadcrumbs path={[
        { name: 'Shops', active: true, link: '/Shops' },
        { name: `Shop - ${match.params.id}`, active: false }
      ]}
      />
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        position: 'sticky',
        backgroundColor: 'white',
        top: '0',
        padding: '.5rem',
        zIndex: 100
      }}
      >
        <Button className="bg-primary" onClick={saveCurrent}>Save</Button>
        &nbsp;
        <Button className="bg-danger"><Link to="/Shops" style={{ textDecoration: 'none', color: 'inherit' }}>Cancel</Link></Button>
      </div>
      <div>
        {
          saving ? (
            <div style={{
              display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
            }}
            />
          ) : <></>
        }

        <Container fluid>
          {
            loading ? (
              <div style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
              }}
              >
                <Loader
                  type="TailSpin"
                  color="#3b78e7"
                  height={150}
                  width={150}
                />
                Loading Report Data...
              </div>
            )
              : (
                <Container fluid>
                <FormGroup>
                  <Label for="ShopName">Shop Name</Label>
                  <Input type="text" name="ShopName" id="ShopName" onChange={handleChange} value={Shop.data.ShopName || ''} />
              </FormGroup>
                  <FormGroup>
                    <Label for="ShopName">Shop Name</Label>
                    <RadioGroup name="Status" id="Status" selectedValue={Shop.data.Status || ''} onChange={handleChange}>
                      <Radio value="Active" label="Active" name="Status" style={{ transform: 'scale(1.5)' }} />
                      <Radio value="InActive" label="InActive" name="Status" style={{ transform: 'scale(1.5)' }} />
                    </RadioGroup>
                  </FormGroup>
                </Container>
              )
          }
        </Container>
      </div>
    </>
  );
}


Shops.propTypes = {};
Shops.defaultProps = {};


export default Shops;