import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  Col,
  Row,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
} from 'reactstrap';
import * as yup from 'yup';
import EnterpriseTypeahead from '../_DataComponents/EnterpriseTypeahead';
import Photos from '../_DataComponents/photos';
import { RadioGroup, Radio } from '../_DataComponents/Radio';
import { config } from '../firebase';
import { UserContext } from '../providers/UserProvider';

const GeneralInfo = (props) => {
  const user = useContext(UserContext);

  const {
    info,
    handleChange,
    disabled,
    schema
  } = props;

  const OccurrenceTypeDescription = () => {
    switch (info.data.OccurrenceType) {
      case 'Incident':
        return 'An undesired or unexpected event resulting in injury and / or damage to property or equipment';
      case 'Near Miss':
        return 'An event where no property was damaged and no personal injury sustained, but where, given a slight shift in time or position, damage and/or injury easily could have occurred.';
      case 'Good Catch':
        return 'Is a condition or situation that has the potential to cause an incident but that did not actually occur due to corrective action and/or timely intervention of an employee.';
      default:
        return '';
    }
  };

  const CategoryTypeDescription = () => {
    switch (info.data.CategoryType) {
      case 'Aldridge Group Employee Injury/Illness':
      case 'Third Party Injury/Illness':
      case 'Subcontractor Injury/Illness':
        return 'An abnormal physical condition or disorder.';
      case 'Property Damage':
        return 'Damage to public or private property incurred during equipment operations or other work activity.';
      case 'Theft/Vandalism':
        return 'The wrongful taking of personal property or other goods. The act of deliberate destruction, or damage, to public or private property.';
      case 'Motor Vehicle':
        return 'Any incident involving a motor vehicle that results in injury or damage.';
      case 'Motor Vehicle=Theft/Vandalism':
        return 'Any incident involving a motor vehicle that results in injury or damage. The wrongful taking of personal property or other goods. The act of deliberate destruction, or damage, to public or private property.';
      case 'Equipment Damage':
        return 'Damage to equipment that occurs during equipment operations or as a result of mechanical failure.';
      case 'Utility Hit':
        return '– Any incident where an underground or overhead utility hit occurs regardless of the extent of damage.';
      default:
        return '';
    }
  };

  const OccurrenceTypes = () => {
    if (info.data.ReportType === 'Safety') {
      return (
        <RadioGroup name="OccurrenceType" id="OccurrenceType" selectedValue={info.data.OccurrenceType || ''} onChange={handleChange} invalid={!yup.reach(schema, 'data.OccurrenceType').isValidSync(info.data.OccurrenceType)} disabled={disabled}>
          <Radio value="Incident" label="Incident" style={{ transform: 'scale(1.5)' }} />
          <Radio value="Near Miss" label="Near Miss" style={{ transform: 'scale(1.5)' }} />
          <Radio value="Good Catch" label="Good Catch" style={{ transform: 'scale(1.5)' }} />
        </RadioGroup>
      );
    }
    if (info.data.ReportType === 'Quality') {
      return (
        <RadioGroup name="OccurrenceType" id="OccurrenceType" selectedValue={info.data.OccurrenceType || ''} onChange={handleChange} invalid={!yup.reach(schema, 'data.OccurrenceType').isValidSync(info.data.OccurrenceType)} disabled={disabled}>
          <Radio value="Quality Good Catch" label="Quality Good Catch" style={{ transform: 'scale(1.5)' }} />
          <Radio value="Quality Events" label="Quality Events" style={{ transform: 'scale(1.5)' }} />
          <Radio value="Quality Events - Rework" label="Quality Events - Rework" style={{ transform: 'scale(1.5)' }} />
        </RadioGroup>
      );
    }

    return null;
  };
  const requiredMark = () => <span className="text-danger">*</span>;
  return (
    <>
      <TransitionGroup>
        <CSSTransition
          timeout={0}
          classNames="TabsAnimation"
          unmountOnExit
        >
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="id">Report ID</Label>
                  <Input type="text" name="id" value={info.id || ''} readOnly />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="Submitter">Submitter</Label>
                  <Input type="text" name="Submitter" value={info.data.Submitter || ''} readOnly />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="Company">Company {requiredMark()}</Label>
                  <Input
                    type="select"
                    name="Company"
                    id="Company"
                    onChange={handleChange}
                    value={info.data.Company || ''}
                    disabled={disabled}
                    invalid={!yup.reach(schema, 'data.Company').isValidSync(info.data.Company)}
                  >
                    <option>-Select-</option>
                    <option>Aldridge Electric</option>
                    <option>Subcontractor</option>
                    <option>3rd Party</option>
                    <option>Joint Venture</option>

                  </Input>
                </FormGroup>
              </Col>
              <Col md={6} hidden={['Subcontractor', '3rd Party', 'Joint Venture'].indexOf(info.data.Company) === -1}>
                {
                  info.data.Company === 'Subcontractor'
                    ? (
                      <EnterpriseTypeahead
                        onChange={handleChange}
                        name="CompanyOther"
                        valueKey="aptvenid"
                        optionDisplay={['option_VendorName']}
                        label="Subcontractor"
                        value={(typeof info.data.CompanyOther !== 'undefined' && info.data.CompanyOther.length !== 0) ? info.data.CompanyOther : -1}
                        collection="Subcontractors"
                        orderBy="VendorName"
                        disabled={disabled}
                        required
                      />
                    )
                    : (
                      <FormGroup>
                        <Label for="CompanyOther">{(info.data.Company === '3rd Party') ? '3rd Party' : 'Joint Venture'}</Label>
                        <Input type="text" name="CompanyOther" id="CompanyOther" onChange={handleChange} value={info.data.CompanyOther || ''} disabled={disabled} />
                      </FormGroup>
                    )
                }
              </Col>
            </Row>

            <Row>
              <Col>
                <EnterpriseTypeahead
                  onChange={handleChange}
                  name="Division"
                  valueKey="DivisionNumber"
                  optionDisplay={['option_DivisionName']}
                  label="Division"
                  value={(typeof info.data.Division !== 'undefined' && info.data.Division.length !== 0) ? info.data.Division : -1}
                  collection="Divisions"
                  where={[['Active', '==', 'True']]}
                  orderBy="DivisionName"
                  disabled={disabled}
                  required
                  invalid={!yup.reach(schema, 'data.Division').isValidSync(info.data.Division)}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="ReportType">Report Type {requiredMark()}</Label>
                  <RadioGroup name="ReportType" id="ReportType" selectedValue={info.data.ReportType || ''} onChange={handleChange} disabled={disabled}>
                    <Radio value="Safety" label="Safety" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Quality" label="Quality" style={{ transform: 'scale(1.5)' }} />
                  </RadioGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="OccurrenceType">Occurrence Type {requiredMark()}</Label>
                  {OccurrenceTypes()}
                  <FormText>
                    {OccurrenceTypeDescription()}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            {info.data.OccurrenceType === 'Quality Events - Rework'
              ? (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="Cost">Cost</Label>
                        <Input
                          type="number"
                          name="Cost"
                          id="Cost"
                          onChange={handleChange}
                          value={info.data.Cost || ''}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="Hours">Hours</Label>
                        <Input
                          type="number"
                          name="Hours"
                          id="Hours"
                          onChange={handleChange}
                          value={info.data.Hours || ''}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="CategoryType">Category Type {requiredMark()}</Label>
                  <RadioGroup name="CategoryType" id="CategoryType" selectedValue={info.data.CategoryType || ''} onChange={handleChange} invalid={!yup.reach(schema, 'data.CategoryType').isValidSync(info.data.CategoryType)} disabled={disabled}>
                    <Radio value="Vendor Issue" label="Vendor Issue" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) === -1} />
                    <Radio value="Damaged Work or Mat'l" label="Damaged Work or Mat'l" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) === -1} />
                    <Radio value="Material Defect" label="Material Defect" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) === -1} />
                    <Radio value="Deficient Work by AE" label="Deficient Work by AE" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) === -1} />
                    <Radio value="Deficient Work By Other(s)" label="Deficient Work By Other(s)" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) === -1} />
                    <Radio value="Design Issue" label="Design Issue" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) === -1} />
                    <Radio value="Document Control" label="Document Control" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) === -1} />
                    <Radio value="Other" label="Other" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) === -1} />
                    <Radio value="Aldridge Group Employee Injury/Illness" label="Aldridge Group Employee Injury/Illness" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                    <Radio value="Third Party Injury/Illness" label="Third Party Injury/Illness" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                    <Radio value="Subcontractor Injury/Illness" label="Subcontractor Injury/Illness" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                    <Radio value="Property Damage" label="Property Damage" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                    <Radio value="Theft/Vandalism" label="Theft/Vandalism" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                    <Radio value="Motor Vehicle" label="Motor Vehicle" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                    <Radio value="Motor Vehicle-Theft/Vandalism" label="Motor Vehicle-Theft/Vandalism" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                    <Radio value="Equipment Damage" label="Equipment Damage" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                    <Radio value="Environmental" label="Environmental" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                    <Radio value="Utility Hit" label="Utility Hit" style={{ transform: 'scale(1.5)' }} hidden={['Quality Good Catch', 'Quality Events', 'Quality Events - Rework'].indexOf(info.data.OccurrenceType) !== -1} />
                  </RadioGroup>
                  <FormText>
                    {CategoryTypeDescription()}
                  </FormText>
                </FormGroup>

                <FormGroup hidden={['Motor Vehicle'].indexOf(info.data.CategoryType) === -1} disabled={disabled}>
                  <Label for="MVIType">MVI Type</Label>
                  <RadioGroup name="MVIType" id="MVIType" selectedValue={info.data.MVIType || ''} onChange={handleChange} disabled={disabled}>
                    <Radio value="Backing Incident - Spotter Utilized" label="Backing Incident - Spotter Utilized" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Backing Incident - No Spotter Utilized " label="Backing Incident - No Spotter Utilized " style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Contact with a Moving Vehicle" label="Contact with a Moving Vehicle" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Contact with a Stationary Vehicle" label="Contact with a Stationary Vehicle" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Contact with a Stationary Object" label="Contact with a Stationary Object" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Other" label="Other" style={{ transform: 'scale(1.5)' }} />
                  </RadioGroup>
                  <Label for="MVITypeOther" hidden={info.data.MVIType !== 'Other'}>MVI Type (Other)</Label>
                  <Input type="text" name="MVITypeOther" hidden={info.data.MVIType !== 'Other'} value={info.data.MVIType || ''} onChange={handleChange} />
                </FormGroup>

                <FormGroup hidden={['Motor Vehicle'].indexOf(info.data.CategoryType) === -1} disabled={disabled}>
                  <Label for="PrimaryCause">Primary Cause</Label>
                  <RadioGroup name="PrimaryCause" id="PrimaryCause" selectedValue={info.data.PrimaryCause || ''} onChange={handleChange} disabled={disabled}>
                    <Radio value="Following too Closely" label="Following too Closely" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Driving too Fast for Conditions" label="Driving too Fast for Conditions" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Distraction" label="Distraction" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Fatigue" label="Fatigue" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Mechanical Failure" label="Mechanical Failure" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Obstructed View" label="Obstructed View  (sun glare would fall into this category)" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Other" label="Other" style={{ transform: 'scale(1.5)' }} />
                  </RadioGroup>
                  <Label for="PrimaryCauseOther" hidden={info.data.PrimaryCause !== 'Other'}>MVI Type (Other)</Label>
                  <Input type="text" name="PrimaryCauseOther" hidden={info.data.PrimaryCause !== 'Other'} value={info.data.PrimaryCause || ''} onChange={handleChange} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup hidden={['Property Damage', 'Theft/Vandalism', 'Motor Vehicle', 'Motor Vehicle-Theft/Vandalism', 'Equipment Damage', 'Environmental', 'Utility Hit'].indexOf(info.data.CategoryType) === -1} disabled={disabled}>
                  <Label for="EstimatedIncidentCost">Estimated Incident Cost {requiredMark()}</Label>
                  <RadioGroup name="EstimatedIncidentCost" id="EstimatedIncidentCost" selectedValue={info.data.EstimatedIncidentCost || ''} onChange={handleChange} disabled={disabled}>
                    <Radio value="$0.00" label="$0.00" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="$1 - $50" label="$1 - $50" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="$50 - $99" label="$50 - $99" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="$100 - $499" label="$100 - $499" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="$500 - $999" label="$500 - $999" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="$1,000 - $2,999" label="$1,000 - $2,999" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="$3,000 - $4,999" label="$3,000 - $4,999" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="$5,000 - $9,999" label="$5,000 - $9,999" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="$10,000+" label="$10,000+" style={{ transform: 'scale(1.5)' }} />
                  </RadioGroup>
                </FormGroup>
                <FormGroup hidden={['Motor Vehicle', 'Motor Vehicle-Theft/Vandalism'].indexOf(info.data.CategoryType) === -1}>
                  {
                    user && user.admin ? (
                      <>
                        <Label for="Auto">Auto {(info.data.CategoryType === 'Motor Vehicle-Theft/Vandalism') ? '' : requiredMark()}</Label>
                        <RadioGroup name="Auto" id="Auto" selectedValue={info.data.Auto || ''} onChange={handleChange} disabled={disabled} invalid={!yup.reach(schema, 'data.OccurrenceType').isValidSync(info.data.Auto)}>
                          <Radio value="At-Fault" label="At-Fault" style={{ transform: 'scale(1.5)' }} />
                          <Radio value="Not At-Fault" label="Not At-Fault" style={{ transform: 'scale(1.5)' }} />
                        </RadioGroup>
                      </>
                    ) : <></>
                  }
                </FormGroup>

                <FormGroup hidden={['Motor Vehicle', 'Motor Vehicle-Theft/Vandalism', 'Equipment Damage'].indexOf(info.data.CategoryType) === -1}>
                  <Label for="Damage">Equipment Type Damaged</Label>
                  <Input type="select" name="Damage" id="Damage" onChange={handleChange} value={info.data.Damage || ''} disabled={disabled}>
                    <option>-Select-</option>
                    <option>Aerial Lift</option>
                    <option>Attenuator</option>
                    <option>Auger</option>
                    <option>Baker Tank</option>
                    <option>Boom Truck</option>
                    <option>Bucket Truck</option>
                    <option>Bulldozer</option>
                    <option>Company Vehicle</option>
                    <option>Crane</option>
                    <option>Digger Derrick</option>
                    <option>Drill Rig</option>
                    <option>Dump Truck</option>
                    <option>Electronic Tamper</option>
                    <option>Excavator</option>
                    <option>Fork Lift</option>
                    <option>Loader</option>
                    <option>Plow Truck</option>
                    <option>Private Vehicle</option>
                    <option>Rental Car</option>
                    <option>Semi</option>
                    <option>SWAT Truck</option>
                    <option>Trailer</option>
                    <option>UTV</option>
                    <option>Vac Truck</option>
                    <option>Water Truck</option>
                    <option>Other</option>
                  </Input>

                  <FormGroup hidden={info.data.Damage !== 'Other'}>
                    <Label for="EquipmentOther">Equipment Type Damaged (Other)</Label>
                    <Input type="text" name="EquipmentOther" value={info.data.EquipmentOther || ''} onChange={handleChange} />
                  </FormGroup>

                  <Label for="EquipmentNumber">Equipment/Vehicle Number (Enter 0 if Equipment Does not have a Number or Apply to Occurrence)</Label>
                  <Input type="text" name="EquipmentNumber" id="EquipmentNumber" onChange={handleChange} value={info.data.EquipmentNumber || ''} disabled={disabled} />
                </FormGroup>

                <FormGroup hidden={['Utility Hit'].indexOf(info.data.CategoryType) === -1}>
                  <Label for="UtilityDamage">Utility Damage</Label>
                  <RadioGroup name="UtilityDamage" id="UtilityDamage" selectedValue={info.data.UtilityDamage || ''} onChange={handleChange} disabled={disabled}>
                    <Radio value="Mismarked" label="Mismarked" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Located" label="Located" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="Unlocated" label="Unlocated" style={{ transform: 'scale(1.5)' }} />
                  </RadioGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row hidden={['Near Miss', 'Good Catch'].indexOf(info.data.OccurrenceType) === -1}>
              <Col>
                <FormGroup>
                  <Label for="BeginToolUsed">Was the BEGIN Tool Used? {requiredMark()}</Label>
                  <RadioGroup name="BeginToolUsed" id="BeginToolUsed" selectedValue={info.data.BeginToolUsed || ''} onChange={handleChange} disabled={disabled}>
                    <Radio value="Yes" label="Yes" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="No" label="No" style={{ transform: 'scale(1.5)' }} />
                  </RadioGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="DescribeIncident">Describe the Incident {requiredMark()}</Label>
                  <Input
                    name="DescribeIncident"
                    type="textarea"
                    value={info.data.DescribeIncident}
                    onChange={handleChange}
                    disabled={disabled}
                    invalid={!yup.reach(schema, 'data.DescribeIncident').isValidSync(info.data.DescribeIncident)}
                  />
                </FormGroup>
              </Col>
            </Row>
            {info.data.ReportType === 'Quality'
              ? (
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="CorrectiveActions">Corrective Actions/Lessons Learned</Label>
                      <Input
                        name="CorrectiveActions"
                        type="textarea"
                        value={info.data.CorrectiveActions || ''}
                        onChange={handleChange}
                        disabled={disabled}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )
              : null}

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="IncidentDate">Date/Time of Incident {requiredMark()}</Label>
                  <Input
                    type="datetime-local"
                    name="IncidentDate"
                    id="IncidentDate"
                    onChange={handleChange}
                    value={info.data.IncidentDate || ''}
                    disabled={disabled}
                    invalid={!yup.reach(schema, 'data.IncidentDate').isValidSync(info.data.IncidentDate)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="ReportDate">Report Date</Label>
                  <Input type="date" name="ReportDate" id="ReportDate" max={new Date().toISOString().split('T')[0]} onChange={handleChange} value={info.data.ReportDate || ''} disabled={disabled} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <EnterpriseTypeahead
                  onChange={handleChange}
                  name="ProjectManager"
                  valueKey="prtmstid"
                  optionDisplay={['option_PreferredFirstName', ' ', 'option_MiddleName', ' ', 'option_PreferredLastName', ' ', 'option_Suffix']}
                  label="Project Manager"
                  value={(typeof info.data.ProjectManager !== 'undefined' && info.data.ProjectManager.length !== 0) ? info.data.ProjectManager : -1}
                  collection="Employees"
                  where={[['Status', '==', 'A']]}
                  orderBy="PreferredLastName"
                  disabled={disabled}
                  required
                  invalid={!yup.reach(schema, 'data.ProjectManager').isValidSync(info.data.ProjectManager)}
                />
              </Col>
              <Col>
                <EnterpriseTypeahead
                  onChange={handleChange}
                  name="Superintendent"
                  valueKey="prtmstid"
                  optionDisplay={['option_PreferredFirstName', ' ', 'option_MiddleName', ' ', 'option_PreferredLastName', ' ', 'option_Suffix']}
                  label="Superintendent in Charge of Work"
                  value={(typeof info.data.Superintendent !== 'undefined' && info.data.Superintendent.length !== 0) ? info.data.Superintendent : -1}
                  collection="Employees"
                  where={[['Status', '==', 'A']]}
                  orderBy="PreferredLastName"
                  disabled={disabled}
                />
              </Col>
              <Col>
                <EnterpriseTypeahead
                  onChange={handleChange}
                  name="VicePresident"
                  valueKey="prtmstid"
                  optionDisplay={['option_PreferredFirstName', ' ', 'option_MiddleName', ' ', 'option_PreferredLastName', ' ', 'option_Suffix']}
                  label="Vice President in Charge of Work"
                  value={(typeof info.data.VicePresident !== 'undefined' && info.data.VicePresident.length !== 0) ? info.data.VicePresident : -1}
                  collection="Employees"
                  where={[['Status', '==', 'A']]}
                  orderBy="PreferredLastName"
                  disabled={disabled}
                  required
                  invalid={!yup.reach(schema, 'data.VicePresident').isValidSync(info.data.VicePresident)}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="SupervisorInformed">Was the Supervisor Informed Immediately?</Label>
                  <RadioGroup name="SupervisorInformed" id="SupervisorInformed" selectedValue={info.data.SupervisorInformed || ''} onChange={handleChange} disabled={disabled}>
                    <Radio value="Yes" label="Yes" name="SupervisorInformed" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="No" label="No" name="SupervisorInformed" style={{ transform: 'scale(1.5)' }} />
                  </RadioGroup>
                </FormGroup>
              </Col>
              <Col>

                <FormGroup hidden={info.data.SupervisorInformed !== 'No'}>
                  <Label for="SupervisorInformedExplain">Explain Why Supervisor wasn&#39;t Informed Immediately</Label>
                  <Input type="textarea" name="SupervisorInformedExplain" id="SupervisorInformedExplain" onChange={handleChange} value={info.data.SupervisorInformedExplain || ''} disabled={disabled} />
                </FormGroup>
              </Col>
            </Row>

            <Row hidden={info.data.ReportType === 'Quality'}>
              <Col>
                <FormGroup>
                  <Label for="Was911Contacted">Was 911 contacted?</Label>
                  <RadioGroup name="Was911Contacted" id="Was911Contacted" selectedValue={info.data.Was911Contacted || ''} onChange={handleChange} disabled={disabled}>
                    <Radio value="Yes" label="Yes" name="Was911Contacted" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="No" label="No" name="Was911Contacted" style={{ transform: 'scale(1.5)' }} />
                  </RadioGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="Explanation911">Explain</Label>
                  <Input type="textarea" name="Explanation911" id="Explanation911" onChange={handleChange} value={info.data.Explanation911 || ''} disabled={disabled} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup hidden={info.data.Was911Contacted !== 'Yes'}>
                  <Label for="WasAmbulanceDispatched">Was an ambulance dispatched</Label>
                  <RadioGroup name="WasAmbulanceDispatched" id="WasAmbulanceDispatched" selectedValue={info.data.WasAmbulanceDispatched || ''} onChange={handleChange} disabled={disabled}>
                    <Radio value="Yes" label="Yes" name="WasAmbulanceDispatched" style={{ transform: 'scale(1.5)' }} />
                    <Radio value="No" label="No" name="WasAmbulanceDispatched" style={{ transform: 'scale(1.5)' }} />
                  </RadioGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <FormGroup>
                <Photos name="Photo" label="Photos" multiple storageEndpoint={`${info.id}/photo`} zipEndpoint="zip" bucket={config.storageBucket} uploadText="Attach Photos" zipText="Download All" folder={`${info.id}/photo`} />
              </FormGroup>
            </Row>

          </Form>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

GeneralInfo.propTypes = {
  info: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func,
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.objectOf(PropTypes.string, PropTypes.object),
    push: PropTypes.func,
    replace: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    block: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string
  }),
  disabled: PropTypes.bool,
  schema: PropTypes.objectOf(PropTypes.any).isRequired
};
GeneralInfo.defaultProps = {
  info: {},
  handleChange: () => { },
  history: {
    push: () => { }
  },
  match: {
    url: ''
  },
  disabled: false
};

export default GeneralInfo;
