/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import {
  Button
} from 'reactstrap';
import * as yup from 'yup';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import html2pdf from 'html2pdf.js';

import EnterpriseTypeahead from '../_DataComponents/EnterpriseTypeahead';
import { RadioGroup, Radio } from '../_DataComponents/Radio';
import { previewEmail } from '../_DataComponents/FirstReportEmail';
import { storage } from '../firebase';
import { useFirebase } from '../_helpers/fetch';

const EmailPreview = (props) => {
  const {
    handleChange,
    info,
    setInfo,
    disabled,
    schema
  } = props;
  const isComponentMounted = useRef(true);
  const [attachments, setAttachments] = useState([]);

  const {
    data: Photos,
    loading: PhotosLoading,
    error: PhotosError,
    firebaseCall: PhotosCall,
    refetch: newPhotosCall
  } = useFirebase('getFiles', { path: `${info.id}/photo` }, isComponentMounted, [], 'name');

  const bodyPartChange = (event) => {
    const { value } = event.target;
    const infocopy = { ...info };
    if (typeof infocopy.data.BodyPart === 'undefined') infocopy.data.BodyPart = [];
    const bodyPartIdx = infocopy.data.BodyPart.indexOf(value);
    if (bodyPartIdx < 0) {
      infocopy.data.BodyPart.push(value);
    }
    else {
      infocopy.data.BodyPart.splice(bodyPartIdx, 1);
    }
    setInfo(infocopy);
  };

  useEffect(() => {
    if (info.id !== '') {
      PhotosCall();
    }
  }, [info.id]);
  useEffect(() => {
    setAttachments(Photos.map((res) => ({ path: res.url, filename: res.name })));
  }, [Photos]);
  // useEffect(() => {
  //   let mounted = true;

  //   const getItems = async () => {
  //     const ref = storage.ref(`${info.id}/photo`);
  //     const urls = [];
  //     function getBase64Image(img) {
  //       const canvas = document.createElement('canvas');
  //       canvas.width = img.width;
  //       canvas.height = img.height;
  //       const ctx = canvas.getContext('2d');
  //       ctx.drawImage(img, 0, 0);
  //       const dataURL = canvas.toDataURL('image/png');
  //       return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  //     }
  //     const listItems = (await ref.listAll()).items;
  //     for (let i = 0; i < listItems.length; i++) {
  //       // eslint-disable-next-line no-await-in-loop
  //       const url = await listItems[i].getDownloadURL();
  //       // eslint-disable-next-line no-await-in-loop
  //       const b64 = getBase64Image(url);
  //       urls.push({ filename: listItems[i].name, path: b64 });
  //     }
  //     setAttachments(urls);
  //   };

  //   if (mounted) getItems();
  //   // eslint-disable-next-line no-return-assign
  //   return (() => mounted = false);
  // }, []);

  const ref = useRef();
  const click = () => {
    const opt = {
      margin: [10, 0, 0, 0],
      filename: `First Incident Report - ${info.data.DivisionDisplay} - ${info.data.Submitter} [${info.data.OccurrenceType}].pdf`,
      pagebreak: { after: 'table' }
    };
    const f = html2pdf().from(ref.current).set(opt).save();
  };

  return (
    <>{info.data.OccurrenceType !== 'Quality Events - Rework' ? (
      <>
        <Button onClick={click}>Download PDF</Button>
        <div dangerouslySetInnerHTML={{ __html: previewEmail(info, attachments) }} style={{ margin: '0 auto', width: '720px' }} ref={ref} />
      </>
    ) : <h4 style={{ textAlign: 'center' }}>Emails are not sent for this occurence type.</h4>}
    </>
  );
};

EmailPreview.propTypes = {
  handleChange: PropTypes.func.isRequired,
  info: PropTypes.objectOf(PropTypes.any).isRequired,
  setInfo: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  schema: PropTypes.objectOf(PropTypes.any).isRequired
};

export default EmailPreview;
