/* eslint-disable */
import _ from 'lodash';
import { toast } from 'react-toastify';

const generateEmail = (datobj, attachments, skipped) => {
  const REPORTTYPE =
    datobj.data.ReportType === 'Quality'
      ? 'Quality Event Report'
      : 'Safety First Report';
  const General = {
    Submitter: 'Submitter',
    Company: 'Company',
    DivisionDisplay: 'Division',
    OccurrenceType: 'Occurrence Type',
    CategoryType: 'Category Type',
    EquipmentNumber: 'Equipment Number',
    BeginToolUsed: 'Was BEGIN Tool Used? ',
    DescribeIncident: 'Description',
    IncidentDate_Date: 'Date of Incident',
    IncidentDate_Time: 'Time of Incident',
    ProjectManagerDisplay: 'Project Manager',
    SuperintendentDisplay: 'Superintendent',
    VicePresidentDisplay: 'Vice President',
    SupervisorInformed: 'Supervisor Informed?',
    Was911Contacted: 'Was 911 Contacted?',
    Explanation911: '911 Explanation',
  };
  const Job = {
    IncidentLocation: 'Incident Location',
    City: 'City',
    State: 'State',
  };
  const Employee = {
    ForemanDisplay: 'Foreman Reporting Incident',
    ReportDate: 'Report Date',
    PrimaryEmployeeAffectedDisplay: 'Primary Employee Involved',
  };
  const header = `
      <table style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;text-align:left;border:1px solid #ddd;width:100%">
        <tr>
          <td colspan="4" valign="top" style="font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem">
            <div style="margin-left:0.000px">${REPORTTYPE} Information</div>
          </td>
        </tr>`;

  let content = '';
  const specialcats = [
    'Property Damage',
    'Theft/Vandalism',
    'Motor Vehicle',
    'Motor Vehicle-Theft/Vandalism',
    'Equipment Damage',
    'Environmental',
    'Utility Hit',
  ];
  Object.keys(General).forEach((prop) => {
    let info =
      typeof datobj.data[prop] === 'undefined' ? '' : datobj.data[prop];
    switch (prop) {
      case 'Company':
        if (info !== 'Aldridge Electric') {
          info += ` - ${
            info === '3rd Party' || info === 'Joint Venture'
              ? datobj.data.CompanyOther
              : datobj.data.CompanyOtherDisplay
          }`;
        }
        break;
      case 'CategoryType':
        if (specialcats.includes(info)) {
          info += `\nEstimated Incident Cost: ${datobj.data.EstimatedIncidentCost}`;
        }
        if (
          ['Motor Vehicle', 'Motor Vehicle-Theft-Vandalism'].indexOf(info) > -1
        ) {
          info += `\nAuto: ${datobj.data.Auto}\nEquipment/Vehicle Number: ${datobj.data.EquipmentNumber}`;
        }
        if (info === 'Equipment Damage') {
          info += `\nDamage: ${datobj.data.Damage}\nEquipment/Vehicle Number: ${datobj.data.EquipmentNumber}`;
        }
        if (info === 'Utility Hit') {
          info += `\nDamage: ${datobj.data.utilitydamage}`;
        }
        break;
      case 'SupervisorInformed':
        if (info === 'No') {
          info += `\nWhy supervisor wasn't informed immediately: ${datobj.data.SupervisorInformedExplain}`;
        }
        break;
      case 'Was911Contacted':
        if (info === 'Yes') {
          info += `\nWas an ambulance dispatched? ${datobj.data.WasAmbulanceDispatched}`;
        }
        break;
      case 'BeginToolUsed':
        if (info === '' || datobj.data.OccurrenceType === 'Incident') {
          return;
        }
        break;
      case 'DateOfIncident':
        info = datobj.data.IncidentDate_Date;
        break;
      case 'TimeOfIncident':
        info = datobj.data.IncidentDate_Time;
        break;
      default:
        break;
    }
    content += `
        <tr>
          <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">${General[prop]}</td>
          <td colspan="3">${info}</td>
        </tr>`;
  });
  content += `<tr>
    <td colspan="4" valign="top" style="font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem">
        <div style="margin-left:0.000px">Job & Background Information</div>
    </td>
    </tr>`;
  Object.keys(Job).forEach((prop) => {
    let info =
      typeof datobj.data[prop] === 'undefined' ? '' : datobj.data[prop];
    switch (prop) {
      case 'IncidentLocation':
        info = `${datobj.data.Location}`;
        break;
      default:
        break;
    }
    content += `
              <tr>
                <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">${Job[prop]}</td>
                <td colspan="3">${info}</td>
              </tr>`;
  });
  content += `<tr>
    <td colspan="4" valign="top" style="font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem">
        <div style="margin-left:0.000px">Employee or Injured Party</div>
    </td>
    </tr>`;
  Object.keys(Employee).forEach((prop) => {
    let info =
      typeof datobj.data[prop] === 'undefined' ? '' : datobj.data[prop];
    switch (prop) {
      case 'ForemanDisplay':
        if (datobj.data.OccurrenceType === 'Near Miss') {
          info = '';
        }
        break;
      case 'PrimaryEmployeeAffectedDisplay':
        if (datobj.data.IsAeEmployee?.toString() !== 'true') {
          info = datobj.data.PrimaryEmployeeAffectedOther;
        }

        if (datobj.data.OccurrenceType === 'Near Miss') {
          info = '';
        }
        break;
      default:
        break;
      //isAeEmployee
    }

    content += `
    <tr>
      <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">${Employee[prop]}</td>
      <td colspan="3">${info}</td>
    </tr>`;
  });

  const footer = `
      </table>
      <a href='${window.location.origin}/reports/${datobj.id}'>
        <div style='font-size:12pt;font-weight:bold;color:#fff;background-color:#4285f4;text-align:center;border-radius: 0.15rem;width: fit-content;margin: 0.5rem 0;padding: 0.5rem 0.75rem;'>View in App</div>
      </a>

      ${
        skipped
          ? 'The sender has opted to skip attaching images as they were too large to send the email. Please view in the app to see attached photos'
          : ''
      }
    `;

  const html = header + content + footer;

  return html;
}

const emailNotification = async (datobj, sendEmail, attachments, skipped) => {
  const REPORTTYPE =
    datobj.data.ReportType === 'Quality'
      ? 'Quality Event Report'
      : 'Safety First Report';
  const html = generateEmail(datobj, attachments, skipped);

  try {
    const toEmailList = (() => {
      if (datobj.CreatedBy === 'tgibbs@aldridgegroup.com') {
        return ['tgibbs@aldridgegroup.com'];
      }
      if (window.location.hostname === 'localhost') {
        return ['tgibbs@aldridgegroup.com'];
      }
      if (window.location.hostname === 'aldg-incidentreport-dev.web.app') {
        return ['itdevelopement@aldridge-electric.com'];
      }
      return ['omills@aldridgegroup.com'];
    })();
    const bccEmailList = (() => {
      if (datobj.CreatedBy === 'tgibbs@aldridgegroup.com') {
        return ['tgibbs@aldridgegroup.com'];
      }
      if (window.location.hostname === 'localhost') {
        return ['tgibbs@aldridgegroup.com', 'ifrishman@aldridgegroup.com'];
      }
      if (datobj.CreatedBy === 'ifrishman@aldridgegroup.com') {
        return ['tgibbs@aldridgegroup.com'];
      }
      if (window.location.hostname === 'aldg-incidentreport-dev.web.app') {
        return ['itdevelopement@aldridge-electric.com'];
      }
      const e = [
        'gcoleman@aldridgegroup.com',
        'firstreport@aldridge-electric.com',
        'tgibbs@aldridgegroup.com',
        datobj.CreatedBy,
        datobj.data.ProjectManagerEmail,
      ];
      return e.filter((a) => a.trim() !== '');
    })();
    const subject = (() => {
      if (
        datobj.CreatedBy === 'tgibbs@aldridgegroup.com' ||
        datobj.CreatedBy === 'ifrishman@aldridgegroup.com'
      ) {
        return `TESTING - ${REPORTTYPE} - ${datobj.data.DivisionDisplay} - ${datobj.data.Submitter} [${datobj.data.OccurrenceType}]`;
      }
      if (window.location.hostname === 'localhost') {
        return `TESTING - ${REPORTTYPE} - ${datobj.data.DivisionDisplay} - ${datobj.data.Submitter} [${datobj.data.OccurrenceType}]`;
      }
      if (window.location.hostname === 'aldg-incidentreport-dev.web.app') {
        return `DEVELOPMENT - ${REPORTTYPE} - ${datobj.data.DivisionDisplay} - ${datobj.data.Submitter} [${datobj.data.OccurrenceType}]`;
      }
      return `${REPORTTYPE} - ${datobj.data.DivisionDisplay} - ${datobj.data.Submitter} [${datobj.data.OccurrenceType}]`;
    })();
    sendEmail({
      to: toEmailList,
      bcc: bccEmailList,
      subject,
      html,
      attachments,
    });
    // const emailresp = await POST(`${window.location.origin}/api/sendemail`, {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     to: 'tgibbs@aldridgegroup.com',
    //     subject: `First Incident Report - ${datobj.division}
    // - ${datobj.referencenumber} [${datobj.psot}]`,
    //     html
    //   })>
    // });
    // toast.success(emailresp.response.message);
  } catch (err) {
    toast.error(err.message);
  }
};

const previewEmail = (datobj, attachments = []) => {
  const html = generateEmail(datobj, attachments);

  try {
    return `<div>${html}</div>`;
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    return '<div></div>';
  }
};

export { emailNotification, previewEmail };
