import PropTypes from 'prop-types';
import React from 'react';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import './footer.css';

const ConfidentialityModal = () => {
  const [modal1, setModalOpen] = useModalState({
    open: false,
    title: 'CONFIDENTIALITY NOTICE:',
    message: 'Unless otherwise indicated or obvious from the nature of the application, the information contained in this application is attorney privileged and confidential information intended for the use of authorized individuals or entities only. If the viewer of this content is not correctly authorized, you are hereby notified that any dissemination, distribution or copying of this content is strictly prohibited.',
    record: 'goodbyehello',
    buttons: [
      {
        text: 'Close',
        onClick: (record, { close }) => {
          close();
        },
      },
    ],
    onBackdropClick: ({ close }) => {
      close();
    },
  });
  return (
    <>
      <ModalBox modal={modal1} />
      <button type="button" className="button" onClick={() => setModalOpen(true)}>
        <u>Click here to review the confidentiality statement.</u>
      </button>
    </>
  );
};

const onLinkClick = () => {
  window.open(
    'https://docs.google.com/document/d/1ql1NhtF_TNMYxlafBCIzjLTa4EUmuwfiStJQvgy1cDw/edit?usp=sharing',
    '_blank'
  );
};

const Footer = (props) => {
  const { version, copy } = props;
  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#343a40',
        padding: '0.25rem',
        color: '#fff'
      }}
      >
        <div>Copyright &copy; {copy}</div>
        <ConfidentialityModal />
        <div>v {version}
          <button type="button" className="button" onClick={onLinkClick}> <u>Update Notes</u></button>
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {
  version: PropTypes.string,
  copy: PropTypes.string
};
Footer.defaultProps = {
  version: '2.5.6',
  copy: 'Aldridge, All Rights Reserved'
};

export default Footer;
