import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const Breadcrumbs = (props) => {
  const { path } = props;
  const crumbs = [];

  for (let i = 0; i < path.length; i++) {
    const crumb = (path[i].active) ? (
      <BreadcrumbItem key={i}>
        { (typeof path[i].onClick !== 'undefined') ? <Link to={path[i].link} onClick={path[i].onClick}>{path[i].name}</Link> : <Link to={path[i].link}>{path[i].name}</Link>}
      </BreadcrumbItem>
    )
      : (
        <BreadcrumbItem key={i} active>{path[i].name}</BreadcrumbItem>
      );
    crumbs.push(crumb);
  }

  return (
    <div>
      <Breadcrumb>{crumbs}</Breadcrumb>
    </div>
  );
};

Breadcrumbs.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    active: PropTypes.bool,
    link: PropTypes.string,
    onClick: PropTypes.func
  }))
};
Breadcrumbs.defaultProps = {
  path: []
};

export default Breadcrumbs;
