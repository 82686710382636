import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { env } from './.env';

export const firebaseConfig = {
  apiKey: env.REACT_APP_APIKEY,
  authDomain: env.REACT_APP_AUTHDOMAIN,
  projectId: env.REACT_APP_PROJECTID,
  storageBucket: env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_MESSAGINGSENDERID,
  appId: env.REACT_APP_APPID,
  measurementId: env.REACT_APP_MEASUREMENTID
};
firebase.initializeApp(firebaseConfig);

const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({
  prompt: 'select_account',
});
let confirmCode = null;
let codeSent = null;

const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  'recaptcha-container',
  {
    size: 'invisible',
    callback: (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      codeSent = true;
      window.confirmationResult = response;
      // onSignInSubmit();
    },
  }
);

if (window.location.hostname === 'localhost') {
  console.log('Local Testing...');
  firebase.functions().useEmulator('localhost', 5001);
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const config = {
  apiKey: env.REACT_APP_APIKEY,
  authDomain: env.REACT_APP_AUTHDOMAIN,
  projectId: env.REACT_APP_PROJECTID,
  storageBucket: env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_MESSAGINGSENDERID,
  appId: env.REACT_APP_APPID,
  measurementId: env.REACT_APP_MEASUREMENTID
};

export const CONFIRM_CODE = confirmCode;
export const CODE_SENT = codeSent;
export const signInWithGoogle = () => {
  auth.signInWithPopup(GoogleProvider);
};
export const signInWithPhone = (callback) => {
  const phoneNumber = document.getElementById('userPhoneNumberLogIn').value;
  const PHONENUMBER = phoneNumber.length === 10 ? `+1${phoneNumber}` : null;
  if (PHONENUMBER === null) {
    throw new Error('Phone Number needs to be 10 characters long.');
  }
  auth.signInWithPhoneNumber(PHONENUMBER, recaptchaVerifier).then((res) => {
    callback(res);
    confirmCode = res;
  });
};
export const verifyCode = (callback) => {
  const code = document.getElementById('userVerificationCode').value;
  confirmCode.confirm(code).then((res) => {
    callback(res);
  });
};
