/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { Typeahead, Token } from 'react-bootstrap-typeahead';
import { firestore } from '../firebase';

const EnterpriseTypeahead = (props) => {
  const {
    value,
    valueKey,
    name,
    optionDisplay,
    label,
    multiple,
    onChange,
    collection,
    required,
    where,
    orderBy,
    filterDataset,
    disabled,
    disableToken,
    renderToken,
    requirement,
    invalid,
    enterprise
  } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // const getData = (isSubscribed) => {
  //   let colRef = firestore.collection(collection);
  //   if (typeof where !== 'undefined') {
  //     where.forEach((w) => {
  //       colRef = colRef.where(w[0], w[1], w[2]);
  //     });
  //   }
  //   if (typeof orderBy !== 'undefined') {
  //     colRef = colRef.orderBy(orderBy);
  //   }
  //   colRef.get()
  //     .then((results) => {
  //       let dataOptions = [];
  //       results.forEach((doc) => {
  //         const record = doc.data();
  //         dataOptions.push(record);
  //       });
  //       if (typeof filterDataset !== 'undefined') {
  //         dataOptions = filterDataset(dataOptions);
  //       }
  //       if (isSubscribed) {
  //         setLoading(false);
  //         setData(dataOptions);
  //       }
  //     });
  // };
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) { setLoading(true); }
    if (requirement !== -1) {
      let colRef = firestore.collection(collection);
      if (typeof where !== 'undefined') {
        where.forEach((w) => {
          colRef = colRef.where(w[0], w[1], w[2]);
        });
      }
      if (typeof orderBy !== 'undefined') {
        colRef = colRef.orderBy(orderBy);
      }
      colRef.get()
        .then((results) => {
          let dataOptions = [];
          results.forEach((doc) => {
            const record = doc.data();
            dataOptions.push(record);
          });
          if (typeof filterDataset !== 'undefined') {
            dataOptions = filterDataset(dataOptions);
          }
          if (isSubscribed) {
            setLoading(false);
            setData(dataOptions);
          }
        });
    }
    return () => { (isSubscribed = false); };
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (requirement !== -1) {
      let colRef = firestore.collection(collection);
      if (typeof where !== 'undefined') {
        where.forEach((w) => {
          colRef = colRef.where(w[0], w[1], w[2]);
        });
      }
      if (typeof orderBy !== 'undefined') {
        colRef = colRef.orderBy(orderBy);
      }
      colRef.get()
        .then((results) => {
          let dataOptions = [];
          results.forEach((doc) => {
            const record = doc.data();
            dataOptions.push(record);
          });
          if (typeof filterDataset !== 'undefined') {
            dataOptions = filterDataset(dataOptions);
          }
          if (isSubscribed) {
            setLoading(false);
            setData(dataOptions);
          }
        });
    }
    return () => { (isSubscribed = false); };
  }, [requirement]);

  const [selected, setSelected] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    if (value !== -1) {
      const current = [];
      for (let i = 0; i < value.length; i++) {
        const rec = data.filter((dataObj) => dataObj[valueKey] === value[i]);
        if (rec.length > 0) current.push(rec[0]);
      }
      if (isSubscribed) setSelected(current);
    }
    return () => { (isSubscribed = false); };
  }, [data, value]);

  if (loading) return <div>Loading Enterprise Data...</div>;
  // if (error) return <div>Error Loading Employees</div>;
  // if (!data) return <div>No Employee Data Found</div>;

  const displayString = (options, optiondisplay) => {
    const displayStr = [];
    optiondisplay.forEach((option) => {
      if(enterprise) {
        const displaycheck = option.indexOf('option_');
        if (displaycheck === -1) displayStr.push(option);
        else displayStr.push(options[option.split('option_')[1]]);
      } else {
        const displaycheck = option.indexOf('option_');
        if (displaycheck === -1) displayStr.push(option);
        else displayStr.push(options.data[option.split('option_')[1]]);
      }
    });
    return displayStr.filter((res) => res !== '').join('').trim();
  };

  const createLabelKeys = (option) => displayString(option, optionDisplay);

  const handleChange = (e) => {
    if (e.length > 1) {
      const display = e.map((res) => createLabelKeys(res)).join(',');
      onChange(e.map((res) => res[valueKey]), name, display);
    }
    else if (e.length === 1) {
      const display = createLabelKeys(e[0]);
      onChange([e[0][valueKey]], name, display);
    }
    else {
      onChange([], name, '');
    }
    setSelected(e);
  };
  return (
    <>
      <Label for={name}>{label} {required ? <span className="text-danger">*</span> : ''}</Label>
      <Typeahead
        onChange={handleChange}
        options={data}
        labelKey={createLabelKeys}
        name={name}
        id={name}
        selected={selected}
        multiple={multiple}
        clearButton={disabled ? false : multiple}
        disabled={disabled}
        renderToken={
        typeof renderToken !== 'undefined' ? renderToken
          : (options, p, idx) => (
            <Token
              key={idx}
              disabled={disableToken}
              onRemove={p.onRemove}
            >
              {createLabelKeys(options)}
            </Token>
          )
        }
        inputProps={{
          className: invalid ? 'is-invalid' : ''
        }}
      />
    </>
  );
};

EnterpriseTypeahead.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  valueKey: PropTypes.string,
  name: PropTypes.string,
  optionDisplay: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  collection: PropTypes.string.isRequired,
  where: PropTypes.arrayOf(PropTypes.any),
  orderBy: PropTypes.string,
  filterDataset: PropTypes.func,
  disabled: PropTypes.bool,
  disableToken: PropTypes.bool,
  renderToken: PropTypes.func,
  requirement: PropTypes.arrayOf(PropTypes.string),
  invalid: PropTypes.bool,
  enterprise: PropTypes.bool,
};
EnterpriseTypeahead.defaultProps = {
  value: undefined,
  valueKey: '',
  name: '',
  optionDisplay: [],
  label: '',
  multiple: false,
  required: false,
  onChange: () => { },
  where: undefined,
  orderBy: undefined,
  filterDataset: undefined,
  disabled: false,
  disableToken: false,
  renderToken: undefined,
  requirement: undefined,
  invalid: false,
  enterprise: true
};

export default EnterpriseTypeahead;
