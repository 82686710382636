import { atom } from 'recoil';

export const CurrentReportID = atom({
  key: 'reportid',
  default: -1,
});

export const CurrentIncidentDate = atom({
  key: 'incidentdate',
  default: -1,
});

export const StartDate = atom({
  key: 'startDate',
  default: -1,
});

export const EndDate = atom({
  key: 'endDate',
  default: -1,
});

export const CurrentDivision = atom({
  key: 'division',
  default: -1,
});

export const CurrentOccurrenceType = atom({
  key: 'occurrencetype',
  default: -1,
});

export const CurrentEmail = atom({
  key: 'email',
  default: -1,
});

export const CurrentLocation = atom({
  key: 'location',
  default: -1,
});
