import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from '../providers/UserProvider';

const Header = (props) => {
  const user = useContext(UserContext);
  const { appTitle } = props;
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark" style={{ minHeight: '56px', width: '100%', backgroundColor: '#3b78e7' }}>
        <span className="navbar-brand">{appTitle}</span>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {
              user && (user.user || user.admin) ? (
                <>
                  <li className="nav-item">
                    <span className="nav-link">
                      <Link to="/">
                        Home
                      </Link>
                    </span>
                  </li>
                  {
                    user.admin ? (
                      <li className="nav-item">
                        <span className="nav-link">
                          <Link to="/Shops">
                            Shops
                          </Link>
                        </span>
                      </li>
                    ) : null
                  }
                </>
              ) : <></>
            }

          </ul>
        </div>
      </nav>
      <div className="brandContainer">
        <div className="brand" />
        <a href="https://www.aldridgegroup.com/">aldridgegroup.com</a>
      </div>
    </>
  );
};

Header.propTypes = {
  appTitle: PropTypes.string
};
Header.defaultProps = {
  appTitle: ''
};

export default Header;
