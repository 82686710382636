import React from 'react';
import PropTypes from 'prop-types';

import {
  Modal, Button
} from 'reactstrap';

const Confirm = (props) => {
  const {
    proceedLabel,
    cancelLabel,
    title,
    modalBody,
    show,
    proceed,
    record
  } = props;
  return (
    <div className="static-modal">
      <Modal
        isOpen={show}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
          <h5 style={{ textDecoration: 'underline' }}>{title}</h5>
        </div>

        <div style={{ position: 'relative', flex: '1 1 auto', padding: '1rem' }}>{modalBody}</div>
        <div style={{
          display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end', padding: '0.75rem'
        }}
        >
          <Button onClick={() => proceed(false, record)} style={{ marginRight: '0.3rem' }}>{cancelLabel}</Button>
          <Button
            color="primary"
            onClick={() => proceed(true, record)}
          >
            {proceedLabel}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

Confirm.propTypes = {
  proceedLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  modalBody: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  record: PropTypes.objectOf(PropTypes.any)
};
Confirm.defaultProps = {
  proceedLabel: '',
  cancelLabel: '',
  title: '',
  modalBody: '',
  show: false,
  proceed: () => {}, // called when ok button is clicked.
  record: {}
};

export default Confirm;
