/* eslint-disable */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Table } from 'reactstrap';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import {
  CurrentIncidentDate,
  CurrentDivision,
  CurrentOccurrenceType,
  CurrentLocation,
  StartDate,
  EndDate,
  // CurrentEmail
} from '../_Recoil/atoms';
import { UserContext } from '../providers/UserProvider';

const ReportTable = (props) => {
  const { reports, queryOptions, headerSelect, fetchMore, onSelect } = props;
  const user = useContext(UserContext);

  const currentLocation = useRecoilValue(CurrentLocation);
  const currentIncidentDate = useRecoilValue(CurrentIncidentDate);
  const currentStartDate = useRecoilValue(StartDate);
  const currentEndDate = useRecoilValue(EndDate);
  const currentDivision = useRecoilValue(CurrentDivision);
  const currentOccurrenceType = useRecoilValue(CurrentOccurrenceType);
  // const currentEmail = useRecoilValue(CurrentEmail);

  const moreRecords = (() => {
    if (reports.length > 0) {
      return reports.length % queryOptions.limit === 0;
    }
    return false;
  })();

  const sortIcon = (currentsort) => {
    if (typeof queryOptions.sort === 'undefined') return <></>;
    if (
      currentsort === queryOptions.sort &&
      typeof queryOptions.direction !== 'undefined'
    ) {
      switch (queryOptions.direction.toLowerCase()) {
        case 'asc':
          return <FontAwesomeIcon icon={faSortUp} className='text-dark' />;
        case 'desc':
          return <FontAwesomeIcon icon={faSortDown} className='text-dark' />;
        default:
          return <FontAwesomeIcon icon={faSort} className='text-dark' />;
      }
    } else return <FontAwesomeIcon icon={faSort} className='text-dark' />;
  };
  const getLocation = (res) => {
    switch (res.data.IncidentLocation) {
      case "Job Site":
        return res.data.JobDisplay;
      case "Office":
        return `Office - ${res.data.City}, ${res.data.State}`;
      case "Yard":
        return `Yard - ${res.data.City}, ${res.data.State}`;
      case "Shop":
        return `${res.data.ShopDisplay}`;
    }

  }
  return (
    <Table hover>
      <thead>
        <tr>
          <th
            style={{ cursor: 'pointer' }}
            onClick={() => {
              headerSelect('IncidentDate_Date');
            }}
          >
            Date of Incident {sortIcon('IncidentDate_Date')}
          </th>
          <th
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (currentLocation === -1) headerSelect('JobDisplay');
            }}
          >
            Location {currentLocation === -1 ? sortIcon('JobDisplay') : <></>}
          </th>
          <th
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (currentDivision === -1) headerSelect('Division');
            }}
          >
            Division {currentDivision === -1 ? sortIcon('Division') : <></>}
          </th>
          <th
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (currentOccurrenceType === -1) headerSelect('OccurrenceType');
            }}
          >
            Occurrence Type{' '}
            {currentOccurrenceType === -1 ? sortIcon('OccurrenceType') : <></>}
          </th>
          <th style={{ cursor: 'pointer' }}>Category Type</th>
          <th style={{ cursor: 'pointer' }}>Submitter</th>
          <th style={{ cursor: 'pointer' }}>Report Status</th>
          <th aria-label='Record Controls' />
        </tr>
      </thead>
      <tbody>
        {reports.length !== 0
          ? reports.map((res) => (
            <tr id={res.id} key={res.id} style={{ cursor: 'pointer' }}>
              <td>
                {dayjs(res.data.IncidentDate).format('MMM DD, YYYY hh:mm A')}
              </td>
              <td>{res.data.Location}</td>
              <td>{res.data.Division}</td>
              <td>{res.data.OccurrenceType}</td>
              <td>{res.data.CategoryType}</td>
              <td>{res.data.Submitter}</td>
              <td>{res.data.Draft ? 'Draft' : 'Submitted'}</td>
              <td style={{ textAlign: 'center' }}>
                <Button
                  className='mt-1'
                  onClick={() => {
                    onSelect('reports', res);
                  }}
                >
                  {res.data.Draft.toString() === 'false' && !user.admin
                    ? 'View'
                    : 'Edit'}
                </Button>
                <span>&nbsp;</span>
                {user.admin ? (
                  <Button
                    className='mt-1'
                    onClick={() => {
                      onSelect('delete', res);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  <></>
                )}
                <span>&nbsp;</span>
                {user.admin && res.data.OccurrenceType !== "Quality Events - Rework" ? (
                  <Button
                    className='mt-1'
                    onClick={() => {
                      onSelect('email', res);
                    }}
                  >
                    Email
                  </Button>
                ) : (
                  <></>
                )}
                <span>&nbsp;</span>
              </td>
            </tr>
          ))
          : null}
      </tbody>
      <tfoot>
        <tr>
          {moreRecords ? (
            <th
              colSpan='5'
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onClick={() => fetchMore()}
            >
              Load More...
            </th>
          ) : (
            <></>
          )}
        </tr>
        <tr>
          <th colSpan='8' style={{ textAlign: 'center' }}>
            {reports.length !== 0 ? (
              `Showing ${reports.length} Records...`
            ) : (
              <></>
            )}
          </th>
        </tr>
      </tfoot>
    </Table>
  );
};

ReportTable.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object),
  queryOptions: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    sort: PropTypes.string,
    direction: PropTypes.string,
  }),
  headerSelect: PropTypes.func,
  onSelect: PropTypes.func,
  //   onDelete: PropTypes.func,
  fetchMore: PropTypes.func,
};
ReportTable.defaultProps = {
  reports: [],
  queryOptions: {
    limit: 100,
    offset: 0,
    sort: null,
    direction: null,
  },
  headerSelect: () => { },
  onSelect: () => { },
  //   onDelete: () => { },
  fetchMore: () => { },
};

export default ReportTable;
