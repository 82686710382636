/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-case-declarations */
import PropTypes from 'prop-types';
import React, {
  useEffect, useRef, useState// , useContext
} from 'react';
import {
  Button,
  CardBody,
  Collapse,
  CardHeader,
  Card,
  CardTitle,
  Label,
  Input,
  Row, Col
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleUp,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons';

import ReportTable from './ReportTable';

import { firestore, storage } from '../firebase';
// import { UserContext } from '../providers/UserProvider';

import {
  CurrentLocation,
  // CurrentIncidentDate,
  CurrentDivision,
  CurrentOccurrenceType,
  CurrentEmail,
  StartDate,
  EndDate
} from '../_Recoil/atoms';

import Breadcrumbs from '../_GlobalComponents/breadcrumb';
import Search from '../_DataComponents/searchbar';
import { useFirebaseMutation } from '../_helpers/fetch';

import { emailNotification } from '../_DataComponents/FirstReportEmail';

const ReportList = (props) => {
  const { history } = props;

  const [reports, setReports] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const isComponentMounted = useRef(true);
  // const user = useContext(UserContext);
  const { firebaseCall: sendEmail } = useFirebaseMutation('SendEmail', {});

  const [currentLocation, SetCurrentLocation] = useRecoilState(CurrentLocation);
  const [currentStartDate, SetStartDate] = useRecoilState(StartDate);
  const [currentEndDate, SetEndDate] = useRecoilState(EndDate);
  // const [currentIncidentDate, SetCurrentIncidentDate] = useRecoilState(CurrentIncidentDate);
  const [currentDivision, SetCurrentDivision] = useRecoilState(CurrentDivision);
  const [currentOccurrenceType, SetCurrentOccurrenceType] = useRecoilState(CurrentOccurrenceType);
  const [currentEmail, SetCurrentEmail] = useRecoilState(CurrentEmail);
  const LASTDOC = useRef(undefined);

  const LocationQuery = {
    name: 'Location',
    options: {
      sort: 'Location'
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'Location'
  };
  const DateQuery = {
    name: 'IncidentDate_Date',
    options: {
      sort: 'IncidentDate'
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'IncidentDate_Date'
  };
  const StartDateQuery = {
    name: 'StartDate',
    options: {
      sort: 'StartDate'
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'StartDate'
  };
  const EndDateQuery = {
    name: 'EndDate',
    options: {
      sort: 'EndDate'
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'EndDate'
  };
  const DivisionQuery = {
    name: 'DivisionDisplay',
    options: {
      sort: 'DivisionDisplay'
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'DivisionDisplay'
  };
  const EmailQuery = {
    name: 'Submitter',
    options: {
      sort: 'Submitter'
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'Submitter'
  };
  const [reportsLoading, setReportsLoading] = useState(false);
  const [QueryOptions, setQueryOptions] = useState({
    limit: 100,
    lastDoc: null,
    sort: null,
    direction: null,
    Location: (currentLocation !== -1) ? currentLocation : null,
    IncidentStartDate: (currentStartDate !== -1) ? currentStartDate : null,
    IncidentEndDate: (currentEndDate !== -1) ? currentEndDate : null,
    Division: (currentDivision !== -1) ? currentDivision : null,
    OccurrenceType: (currentOccurrenceType !== -1) ? currentOccurrenceType : null,
    Submitter: (currentEmail !== -1) ? currentEmail : null,
  });
  // useEffect(() => {
  //   firestore.collection('Reports').get().then((snap) => {
  //     console.log('begin iteration');
  //     const badIds = [];
  //     snap.forEach((doc) => {
  //       const uReport = doc.data();
  //       uReport.forcedUpdated = true;
  //       // if (uReport.data.Location === '' || typeof uReport.data.Location === 'undefined') {
  //       //   if (uReport.data.IncidentLocation === 'Job Site') {
  //       //     uReport.data.Location = uReport.data.JobDisplay;
  //       //   }
  //       //   else if (uReport.data.IncidentLocation === 'Shop') {
  //       //     uReport.data.Location = uReport.data.ShopDisplay;
  //       //   }
  //       //   else {
  //       //     uReport.data.Location = uReport.data.IncidentLocation;
  //       //   }

  //       //   if (typeof uReport.data.Location === 'undefined') {
  //       //     console.log(uReport.data.IncidentLocation, uReport.data.Job);
  //       //     uReport.data.Location = '';
  //       //     badIds.push(uReport.id);
  //       //   }
  //       doc.ref.set(uReport, { merge: true });
  //       // }
  //     });
  //     console.log('end iteration');
  //     console.log(badIds);
  //   });
  // }, []);
  useEffect(() => {
    // ReportListCall();

    const colRef = (() => {
      let ref = firestore.collection('Reports');
      if (QueryOptions.IncidentStartDate !== null && QueryOptions.IncidentStartDate !== '') {
        ref = ref.where('data.IncidentDate_Date', '>=', QueryOptions.IncidentStartDate);
      }
      if (QueryOptions.IncidentEndDate !== null && QueryOptions.IncidentEndDate !== '') {
        ref = ref.where('data.IncidentDate_Date', '<=', QueryOptions.IncidentEndDate);
      }
      if (QueryOptions.Location !== null) {
        ref = ref.where('data.Location', '==', QueryOptions.Location);
      }
      if (QueryOptions.Division !== null) {
        ref = ref.where('data.DivisionDisplay', '==', QueryOptions.Division);
      }
      if (QueryOptions.OccurrenceType !== null) {
        ref = ref.where('data.OccurrenceType', '==', QueryOptions.OccurrenceType);
      }
      if (QueryOptions.Submitter !== null) {
        ref = ref.where('data.Submitter', '==', QueryOptions.Submitter);
      }
      if (QueryOptions.sort !== null) {
        ref = ref.orderBy(`data.${QueryOptions.sort}`, QueryOptions.direction.toLowerCase());
      }
      else {
        ref = ref.orderBy('data.IncidentDate_Date', 'desc');
      }
      if (QueryOptions.lastDoc !== null) {
        ref = ref.startAfter(QueryOptions.lastDoc);
      }
      return ref;
    })();

    colRef.onSnapshot((querySnapshot) => {
      setReportsLoading(true);
      const allReports = [];
      querySnapshot.forEach((doc) => {
        allReports.push(doc.data());
      });
      if (allReports.length > 0) { LASTDOC.current = allReports[allReports.length - 1].id; }
      setReports(allReports);
      setReportsLoading(false);
    }, (err) => {
      console.log(err.message);
      toast.error(err.message);
    });
  }, [
    QueryOptions.IncidentStartDate,
    QueryOptions.IncidentEndDate,
    QueryOptions.Location,
    QueryOptions.Division,
    QueryOptions.OccurrenceType,
    QueryOptions.Submitter,
    QueryOptions.sort,
    QueryOptions.direction,
    QueryOptions.lastDoc
  ]);

  const changeOptions = (field) => {
    const queryOptions = { ...QueryOptions };
    if (field === queryOptions.sort) {
      switch (queryOptions.direction.toLowerCase()) {
        case 'asc':
          queryOptions.sort = field;
          queryOptions.lastDoc = null;
          queryOptions.direction = 'DESC';
          break;
        case 'desc':
        default:
          queryOptions.sort = field;
          queryOptions.lastDoc = null;
          queryOptions.direction = 'ASC';
          break;
      }
    }
    else {
      queryOptions.sort = field;
      queryOptions.direction = 'ASC';
      queryOptions.lastDoc = null;
    }
    setQueryOptions(queryOptions);
  };
  const loadMoreReports = () => {
    const queryOptions = { ...QueryOptions };
    setQueryOptions(queryOptions);
  };

  const filterLocation = (obj) => {
    const searchval = (typeof obj !== 'undefined' && obj.length > 0 && typeof obj[0] !== 'undefined') ? obj[0] : -1;
    SetCurrentLocation(searchval);
    const queryOptions = { ...QueryOptions };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;

    queryOptions.Location = searchval === -1 ? null : searchval;
    setQueryOptions(queryOptions);
  };
  const filterStartDate = (obj) => {
    const searchval = obj.target.value;
    SetStartDate(searchval);
    const queryOptions = { ...QueryOptions };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.IncidentStartDate = searchval === -1 ? null : searchval;
    setQueryOptions(queryOptions);
  };
  const filterEndDate = (obj) => {
    const searchval = obj.target.value;
    SetEndDate(searchval);
    const queryOptions = { ...QueryOptions };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.IncidentEndDate = searchval === -1 ? null : searchval;
    setQueryOptions(queryOptions);
  };
  const filterdivision = (obj) => {
    const searchval = (typeof obj !== 'undefined' && obj.length > 0 && typeof obj[0] !== 'undefined') ? obj[0] : -1;
    SetCurrentDivision(searchval);
    const queryOptions = { ...QueryOptions };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.Division = searchval === -1 ? null : searchval;
    setQueryOptions(queryOptions);
  };
  const filtertype = (e) => {
    const searchval = (typeof e.target !== 'undefined' && e.target.value !== '-Select-') ? e.target.value : -1;
    SetCurrentOccurrenceType(searchval);
    const queryOptions = { ...QueryOptions };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.OccurrenceType = searchval === -1 ? null : searchval;
    setQueryOptions(queryOptions);
  };
  const filteremail = (obj) => {
    const searchval = (typeof obj !== 'undefined' && obj.length > 0 && typeof obj[0] !== 'undefined') ? obj[0] : -1;
    SetCurrentEmail(searchval);
    const queryOptions = { ...QueryOptions };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.Submitter = searchval === -1 ? null : searchval;
    setQueryOptions(queryOptions);
  };

  const handleSelect = async (action, args) => {
    switch (action) {
      case 'email':
        const ref = storage.ref(`${args.id}/photo`);
        const urls = [];
        const listItems = (await ref.listAll()).items;
        for (let i = 0; i < listItems.length; i++) {
          urls.push({ filename: listItems[i].name, path: await listItems[i].getDownloadURL() });
        }
        emailNotification(args, sendEmail, urls);
        break;
      case 'reports':
        // constcrumb=(typeof args.data.id!=='undefined')?`/${action}/${args.data.id}`:`/${action}`;
        history.push(`/${action}/${args.id}`, args);
        break;
      case 'delete':
        if (confirm('Are you sure you want to delete this record?')) {
          firestore.collection('Reports').doc(args.id).delete().then(() => {
            toast.success('Record Successfully Deleted.');
          })
            .catch((err) => {
              toast.error(`Record failed to delete: ${err}`);
            });
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Breadcrumbs path={[{ name: 'Reports', active: false }]} />
      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexWrap: 'wrap'
      }}
      >
        <CardTitle>Reports</CardTitle>
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '1rem' }}>
          <Link to="reports/new">
            <Button color="primary">New Incident</Button>
          </Link>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Card style={{ width: '100%', boxShadow: 'none', marginBottom: '0.3rem' }}>
            <CardHeader>
              <Button block color="link" className="text-left m-0 p-0" onClick={() => { setFiltersOpen(!filtersOpen); }} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5 className="m-0 p-0">Filters</h5>
                {
                  !filtersOpen ? <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: '1.75rem' }} /> : <FontAwesomeIcon icon={faAngleUp} style={{ fontSize: '1.75rem' }} />
                }
              </Button>

            </CardHeader>
            <Collapse isOpen={filtersOpen}>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <Label>Incident Start Date</Label>
                    <Input
                      type="date"
                      name="StartDate"
                      id="StartDate"
                      onChange={filterStartDate}
                      value={currentStartDate || ''}
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Incident End Date</Label>
                    <Input
                      type="date"
                      name="EndDate"
                      id="EndDate"
                      onChange={filterEndDate}
                      value={currentEndDate || ''}
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Division</Label>
                    <Search
                      type="Division"
                      query={DivisionQuery}
                      searchfnc={filterdivision}
                      dataprop="Division"
                      valueKey="Division"
                      optionDisplay={['option_Division']}
                      value={(currentDivision !== -1) ? currentDivision : undefined}
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Location</Label>
                    <Search
                      type="Location"
                      query={LocationQuery}
                      searchfnc={filterLocation}
                      dataprop="Location"
                      valueKey="Location"
                      optionDisplay={['option_Location']}
                      value={(currentLocation !== -1) ? currentLocation : undefined}
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Occurrence Type</Label>
                    <Input
                      type="select"
                      name="type"
                      id="type"
                      onChange={filtertype}
                      value={(currentOccurrenceType !== -1) ? currentOccurrenceType : '-Select-'}
                    >
                      <option>-Select-</option>
                      <option>Incident</option>
                      <option>Near Miss</option>
                      <option>Good Catch</option>
                      <option>Quality Good Catch</option>
                      <option>Quality Events</option>
                      <option>Quality Events - Rework</option>
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Label>Submitter</Label>
                    <Search
                      type="Submitter"
                      query={EmailQuery}
                      searchfnc={filteremail}
                      dataprop="Submitter"
                      valueKey="Submitter"
                      optionDisplay={['option_Submitter']}
                      value={(currentEmail !== -1) ? currentEmail : undefined}
                    />
                  </Col>
                </Row>

              </CardBody>
            </Collapse>
          </Card>
        </div>
      </div>
      {
        reportsLoading
          ? (
            <div style={{
              display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
            }}
            >
              <Loader
                type="TailSpin"
                color="#3b78e7"
                height={100}
                width={100}
              />
              <div>Loading Reports...</div>
            </div>
          )
          : (
            <ReportTable
              reports={reports}
              queryOptions={QueryOptions}
              headerSelect={changeOptions}
              fetchMore={loadMoreReports}
              onSelect={handleSelect}
            />
          )
      }
    </>
  );
};

ReportList.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.objectOf(PropTypes.string, PropTypes.object),
    push: PropTypes.func,
    replace: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    block: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.object),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string
  }),
};
ReportList.defaultProps = {
  history: {
    push: () => { }
  },
  match: {
    url: ''
  }
};

export default ReportList;
